<template>
  <v-app v-if="isLoading">
    <v-content>
      <v-card>
        <v-toolbar color="primary" dark dense fixed>
          <v-btn icon @click.native="backToOrders">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Loading...</v-toolbar-title>
        </v-toolbar>
        <div class="text-xs-center" style="padding: 10rem 0 10rem 0">
          <v-progress-circular
            class="mt-5"
            indeterminate
            size="128"
            color="primary"
            width="8"
          />
        </div>
      </v-card>
    </v-content>
  </v-app>
  <v-app v-else>
    <v-content>
      <v-card>
        <!-- -------------------------------------------------------Toolbar-->
        <v-toolbar color="primary" dark dense fixed>
          <v-btn icon @click.native="backToOrders">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            <HumanDate :date="operation.operationDate" />
          </v-toolbar-title>
          <v-spacer />
          <v-menu offset-y style="z-index: 202;">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile style="cursor: pointer">
                <v-list-tile-title @click="openMapsSearch">
                  {{$t('views.order.order_detail.show_customer_location')}}
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile style="cursor: pointer;">
                <a
                  class="link"
                  :href="addEventToCalendar('apple')"
                  target="_top"
                  download
                >
                  <v-list-tile-title>
                    {{$t('views.order.order_detail.download_calendar_entry')}}
                  </v-list-tile-title>
                </a>
              </v-list-tile>
              <v-list-tile style="cursor: pointer;">
                <v-list-tile-title @click="addEventToCalendar('google')">
                  {{$t('views.order.order_detail.google_calendar_entry')}}
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-toolbar
          color="primary"
          dark
          dense
          fixed
          scroll-off-screen
          inverted-scroll
          :scroll-threshold="300"
        >
          <v-btn icon @click.native="backToOrders">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            SLA:
            <template v-if="kulanzSLA === -1">
              {{$t('views.order.order_detail.none')}}
            </template>
            <template v-else>
              <HumanDate :date="kulanzSLA" />
            </template>
          </v-toolbar-title>
          <!-- toolbarmenu without good use for now
          <ToolbarMenu @mapsSelector="mapsSelector" />-->
        </v-toolbar>
        <hr />
        <!-- -------------------------------------------------------Header-->
        <v-container class="mt-5 nopadding pt-1 d-block" fluid>
          <v-layout>
            <v-flex class="ml-3">
              <h3>
                {{ operation.address.company }}<br />
                {{ operation.address.street }} {{ operation.address.streetNo }}
              </h3>
              <h3>
                {{ operation.address.zip }} {{ operation.address.city }}
              </h3>
              <p class="nomargin">
                {{ operation.ticket_order_nr }}<br />
                {{ operation.ticket_ext_nr }}
              </p>
            </v-flex>
            <!-- ---------------------------------------------------SLA Farbe-->
            <v-flex align-self-end shrink>
              <SlaColorBlock
                :slaTime="kulanzSLA"
                :colored="this.operation.report['finished'] === -1"
              />
              <v-flex align-self-end shrink row>
                <!-- -------------------------------------------------Route-->
                <v-btn
                  class="nopadding nomargin h-4"
                  block
                  x-large
                  flat
                  @click="openMapsDirection"
                >
                  <v-icon color="primary" medium>fa-map</v-icon>
                </v-btn>
                <v-btn
                  class="nopadding nomargin h-4"
                  block
                  x-large
                  flat
                  v-if="hasDocumentationLink"
                  @click="openDocumentation"
                >
                  <v-icon color="primary" medium>fa-book</v-icon>
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-container>
        <hr class="nopadding nomargin" />
        <!-- -------------------------------------------------------Beschreibung-->
        <v-dialog v-model="descr" lazy full-width>
          <v-card slot="activator" style="padding-bottom:0.4rem">
            <p class="ml-2 nopadding nomargin" style="font-size:110%">
              {{$t('views.order.order_detail.customer_description')}}
            </p>
            <div
              v-show="!extendedDescription"
              class="nopadding nomargin ml-4 mr-4 fade"
            >
              {{ operation.description }}
            </div>
            <div
              v-show="extendedDescription"
              class="nopadding nomargin ml-4 mr-4"
            >
              {{ operation.description }}
            </div>
          </v-card>

          <v-card v-if="descr" @click="descr = false">
            <v-card-title class="headline grey lighten-3" primary-title>
              {{$t('views.order.order_detail.customer_description')}}
            </v-card-title>

            <v-card-text>
              <p style="font-size:130%">
                {{ operation.description }}
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-card
          class="ta-center nopadding nomargin mb-2"
          style="height:1.5rem;"
          @click="toggleDescription"
        >
          <v-icon
            v-show="!extendedDescription"
            style="height:1.4rem;"
            color="primary"
          >
            fa-angle-down
          </v-icon>
          <v-icon
            v-show="extendedDescription"
            style="height:1.4rem;"
            color="primary"
          >
            fa-angle-up
          </v-icon>
        </v-card>
        <!-- -------------------------------------------------------ProgressBar-->
        <!--600000===10 Minuten - Kulanzzeit für SLA-->
        <v-progress-linear
          class="nopadding nomargin mb-2"
          :color="getProgressColor()"
          :height="operation.report['travelStart'] !== -1 ? '1.6rem' : '0.8rem'"
          :value="
            operation.report['finished'] !== -1
              ? 100
              : operation.report['repaired'] !== -1
              ? 100
              : operation.report['onSite'] !== -1
              ? 40
              : operation.report['travelStart'] !== -1
              ? 15
              : 1
          "
        >
          <h3 v-if="operation.report['travelStart'] !== -1" class="ta-center">
            <template v-if="operation.report['finished'] !== -1">
              <template
                v-if="
                  kulanzSLA === -1 ||
                    kulanzSLA - operation.report['finished'] >= -600000
                "
              >
                {{$t('views.order.order_detail.completed_within_sla')}}
              </template>
              <template v-else>
                {{$t('views.order.order_detail.completed_within_sla')}}
              </template>
            </template>
            <template v-else-if="operation.report['repaired'] !== -1">
              {{$t('views.order.order_detail.done')}}
            </template>
            <template v-else-if="operation.report['onSite'] !== -1">
              Todo: {{$t('views.order.order_detail.finish_repair')}}
            </template>
            <template v-else>
              Todo: {{$t('views.order.order_detail.arrive_at_the_operation')}}
            </template>
          </h3>
        </v-progress-linear>
        <!-- -------------------------------------------------------Button Start-->
        <v-layout>
          <v-flex shrink class="ml-2">
            <v-btn
              v-show="operation.report['travelStart'] === -1"
              class="status-button"
              @click="
                reportTime('travelStart')
                operation.address.company
                  ? operation.address.company.toLowerCase().indexOf('dmd') !==
                    -1
                    ? send_email('departure')
                    : ''
                  : ''
                setDistance()
              "
            >
              <div>
                <v-icon>fa-car</v-icon>
                <h5>{{$t('views.order.order_detail.departure')}}</h5>
              </div>
            </v-btn>
            <v-dialog
              v-show="operation.report['travelStart'] !== -1"
              v-model="changeTravel"
              width="80%"
              persistent
              :disabled="finished"
            >
              <v-btn
                slot="activator"
                class="status-button"
                :disabled="finished"
                @click="departureTime = operation.report['travelStart']"
              >
                <div>
                  <v-icon>fa-car</v-icon>
                  <h5>{{$t('views.order.order_detail.departure')}}</h5>
                </div>
              </v-btn>
              <v-card v-show="changeTravel">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.travel_start')}}
                </v-card-title>
                <v-card-text>
                  <v-layout>
                    <v-flex shrink>
                      <v-btn @click="departureTime = departureTime - 900000">
                        -15
                      </v-btn>
                      <v-btn @click="departureTime = departureTime - 300000">
                        -5
                      </v-btn>
                      <v-btn @click="departureTime = departureTime - 60000">
                        -1
                      </v-btn>
                    </v-flex>
                    <v-flex grow />
                    <v-flex shrink>
                      <v-btn @click="departureTime = departureTime + 900000">
                        +15
                      </v-btn>
                      <v-btn @click="departureTime = departureTime + 300000">
                        +5
                      </v-btn>
                      <v-btn @click="departureTime = departureTime + 60000">
                        +1
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <h2 class="ta-center">
                    {{getLocalTime(departureTime)}}
                  </h2>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-flex shrink>
                    <v-btn flat color="primary" @click="changeTravel = false">
                      Cancel
                    </v-btn>
                  </v-flex>
                  <v-flex grow />
                  <v-flex shrink>
                    <v-btn
                      flat
                      color="primary"
                      @click="
                        changeTravel = false
                        reportCustomTime('travelStart', departureTime)
                      "
                    >
                      OK
                    </v-btn>
                  </v-flex>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex class="nopadding nomargin" grow />
          <!-- -----------------------------------------------------Button onSite-->
          <v-flex shrink>
            <v-btn
              v-show="operation.report['onSite'] === -1"
              :disabled="operation.report['travelStart'] === -1"
              class="status-button"
              @click="
                reportTime('onSite')
                operation.address.company
                  ? operation.address.company.toLowerCase().indexOf('dmd') !==
                    -1
                    ? send_email('arrival')
                    : ''
                  : ''
              "
            >
              <div>
                <v-icon>fa-wrench</v-icon>
                <h5>{{$t('views.order.order_detail.start')}}</h5>
              </div>
            </v-btn>
            <v-dialog
              v-show="operation.report['onSite'] !== -1"
              v-model="changeArrival"
              width="80%"
              persistent
              :disabled="finished"
            >
              <v-btn
                slot="activator"
                class="status-button"
                :disabled="finished"
                @click="arrivalTime = operation.report['onSite']"
              >
                <div>
                  <v-icon>fa-wrench</v-icon>
                  <h5>{{$t('views.order.order_detail.start')}}</h5>
                </div>
              </v-btn>
              <v-card v-show="changeArrival">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.start_of_work')}}
                </v-card-title>
                <v-card-text>
                  <v-layout>
                    <v-flex shrink>
                      <v-btn @click="arrivalTime = arrivalTime - 900000">
                        -15
                      </v-btn>
                      <v-btn @click="arrivalTime = arrivalTime - 300000">
                        -5
                      </v-btn>
                      <v-btn @click="arrivalTime = arrivalTime - 60000">
                        -1
                      </v-btn>
                    </v-flex>
                    <v-flex grow />
                    <v-flex shrink>
                      <v-btn @click="arrivalTime = arrivalTime + 900000">
                        +15
                      </v-btn>
                      <v-btn @click="arrivalTime = arrivalTime + 300000">
                        +5
                      </v-btn>
                      <v-btn @click="arrivalTime = arrivalTime + 60000">
                        +1
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <h2 class="ta-center">
                    {{getLocalTime(arrivalTime)}}
                  </h2>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-flex shrink>
                    <v-btn flat color="primary" @click="changeArrival = false">
                      Cancel
                    </v-btn>
                  </v-flex>
                  <v-flex grow />
                  <v-flex shrink>
                    <v-btn
                      flat
                      color="primary"
                      @click="
                        changeArrival = false
                        reportCustomTime('onSite', arrivalTime)
                      "
                    >
                      OK
                    </v-btn>
                  </v-flex>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex class="nopadding nomargin" grow />
          <!-- -----------------------------------------------------Button Done-->
          <v-flex class="mr-2" shrink>
            <div
              v-if="true || operation.report['repaired'] === -1"
              class="status-button"
            />
            <template v-if="false && operation.report['repaired'] !== -1">
              <v-btn
                v-show="operation.report['repaired'] === -1"
                :disabled="operation.report['onSite'] === -1"
                class="status-button"
                @click="reportTime('repaired')"
              >
                <div>
                  <v-icon>fa-check</v-icon>
                  <h5>{{$t('views.order.order_detail.end')}}</h5>
                </div>
              </v-btn>
              <v-dialog
                v-show="operation.report['repaired'] !== -1"
                v-model="changeRepaired"
                width="80%"
                persistent
              >
                <v-btn
                  slot="activator"
                  class="status-button"
                  @click="repairedTime = operation.report['repaired']"
                >
                  <div>
                    <v-icon>fa-check</v-icon>
                    <h5>{{$t('views.order.order_detail.end')}}</h5>
                  </div>
                </v-btn>
                <v-card v-show="changeRepaired">
                  <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('views.order.order_detail.end_of_work')}}
                  </v-card-title>
                  <v-card-text>
                    <v-layout>
                      <v-flex shrink>
                        <v-btn @click="repairedTime = repairedTime - 900000">
                          -15
                        </v-btn>
                        <v-btn @click="repairedTime = repairedTime - 300000">
                          -5
                        </v-btn>
                        <v-btn @click="repairedTime = repairedTime - 60000">
                          -1
                        </v-btn>
                      </v-flex>
                      <v-flex grow />
                      <v-flex shrink>
                        <v-btn @click="repairedTime = repairedTime + 900000">
                          +15
                        </v-btn>
                        <v-btn @click="repairedTime = repairedTime + 300000">
                          +5
                        </v-btn>
                        <v-btn @click="repairedTime = repairedTime + 60000">
                          +1
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <h2 class="ta-center">
                      {{getLocalTime(repairedTime)}}
                    </h2>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-flex shrink>
                      <v-btn
                        flat
                        color="primary"
                        @click="changeRepaired = false"
                      >
                        Cancel
                      </v-btn>
                    </v-flex>
                    <v-flex grow />
                    <v-flex shrink>
                      <v-btn
                        flat
                        color="primary"
                        @click="
                          changeRepaired = false
                          reportCustomTime('repaired', repairedTime)
                        "
                      >
                        OK
                      </v-btn>
                    </v-flex>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-flex>
        </v-layout>
        <!-- -------------------------------------------------------Zeit Reisebeginn-->
        <v-layout
          v-show="operation.report['travelStart'] !== -1"
          class="m-1 h-1"
        >
          <v-flex shrink>
            <h3>
              {{$t('views.order.order_detail.travel_start')}}:
            </h3>
          </v-flex>
          <v-flex grow />
          <v-flex shrink>
            <h3 class="ta-right">
              {{getLocalDate(operation.report['travelStart'])}}
            </h3>
          </v-flex>
        </v-layout>
        <!-- ------------------------------Distanz-->
        <v-layout
          v-show="operation.report['travelStart'] !== -1 && hereUsable"
          class="nomargin mr-4 mt-1"
          :style="
            operation.report['onSite'] === -1
              ? 'height:1.5rem;'
              : 'height:0.3rem;'
          "
        >
          <v-flex grow />
          <v-flex class="mr-2" shrink>
            <div class="d-flex flex-column">
              <v-icon small class="nopadding nomargin" color="primary">
                fa-arrows-alt-h
              </v-icon>
              <v-icon
                v-if="operation.report['onSite'] === -1"
                class="nopadding nomargin mt-1"
                color="primary"
                small
              >
                fa-flag-checkered
              </v-icon>
            </div>
          </v-flex>
          <v-flex shrink>
            <div class="d-flex flex-column nopadding nomargin">
              <h4 class="nopadding nomargin ta-right">{{ distance }} km</h4>
              <h4
                v-if="operation.report['onSite'] === -1"
                class="nopadding nomargin ta-right"
              >
                <!--Todo: get real ETA-->
                ~09:48 Uhr
              </h4>
            </div>
          </v-flex>
        </v-layout>
        <!-- -------------------------------------------------------Zeit Arbeitsbeginn-->
        <v-layout v-show="operation.report['onSite'] !== -1" class="m-1 h-1">
          <v-flex shrink>
            <h3>
              {{$t('views.order.order_detail.start_of_work')}}:
            </h3>
          </v-flex>
          <v-flex grow />
          <v-flex shrink>
            <h3
              v-if="
                format(operation.report['onSite'], 'D. MMM YYYY', {
                  locale: deLocale,
                }) ===
                  format(operation.report['travelStart'], 'D. MMM YYYY', {
                    locale: deLocale,
                  })
              "
              class="ta-right"
            >
            {{getLocalTime(operation.report['onSite'])}}
            </h3>
            <h3 v-else class="ta-right">
              {{getLocalDate(operation.report['onSite'])}}
            </h3>
          </v-flex>
        </v-layout>
        <!-- -------------------------------------------------------Zeit Reparatur beendet-->
        <v-layout v-show="operation.report['repaired'] !== -1" class="m-1 h-1">
          <v-flex shrink>
            <h3>
              {{$t('views.order.order_detail.end_of_work')}}:
            </h3>
          </v-flex>
          <v-flex grow />
          <v-flex shrink>
            <h3
              v-if="
                format(operation.report['onSite'], 'D. MMM YYYY', {
                  locale: deLocale,
                }) ===
                  format(operation.report['repaired'], 'D. MMM YYYY', {
                    locale: deLocale,
                  })
              "
              class="ta-right"
            >
              {{getLocalTime(operation.report['repaired'])}}
            </h3>
            <h3 v-else class="ta-right">
              {{getLocalDate(operation.report['repaired'])}}
            </h3>
          </v-flex>
        </v-layout>
        <!-- -------------------------------------------------------Zeit Einsatz beendet-->
        <!-- <v-layout v-show="operation.report['finished'] !== -1" class="m-1 h-2">
          <v-flex shrink>
            <h3>
              Einsatz beendet:
            </h3>
          </v-flex>
          <v-flex grow />
          <v-flex shrink>
            <h3
              v-if="
                format(operation.report['finished'], 'D. MMM YYYY', {
                  locale: deLocale,
                }) ===
                  format(operation.report['repaired'], 'D. MMM YYYY', {
                    locale: deLocale,
                  })
              "
              class="ta-right"
            >
              {{
                format(operation.report['finished'], 'HH:mm', {
                  locale: deLocale,
                })
              }}
              Uhr
            </h3>
            <h3 v-else class="ta-right">
              {{
                format(operation.report['finished'], 'D. MMM YYYY HH:mm', {
                  locale: deLocale,
                })
              }}
              Uhr
            </h3>
          </v-flex>
        </v-layout> -->
        <!-- -------------------------------------------------------HereMap - Test-->
        <div v-if="hereUsable" id="apper">
          <HereMap
            appId="gvygc1xAUNgfexUcKqIa"
            appCode="kcCG-vJRTKblG43lKFGLgg"
            lat="37.7397"
            lng="-121.4252"
            width="100%"
            height="835px"
          />
        </div>

        <v-list class="nopadding nomargin" twoLine>
          <!-- --------------------------------------------------------Entfernung-->
          <template v-if="operation.report['travelStart'] !== -1">
            <hr color="#9FA8DA" />
            <v-card>
              <v-subheader class="h-3">
                {{$t('views.order.order_detail.distance')}}
                <template v-if="distance">
                  <span
                    v-show="distance <= 0"
                    class="red--text"
                    style="margin-left: 6px"
                  >
                    ({{$t('views.order.order_detail.incomplete')}})
                  </span>
                </template>
                <template v-else>
                  <span class="red--text" style="margin-left: 6px">
                    ({{$t('views.order.order_detail.incomplete')}})
                  </span>
                </template>
              </v-subheader>
              <v-list-tile
                v-if="!modal4"
                :disabled="finished"
                @click="
                  !distance || distance <= 0
                    ? (tempText = '')
                    : (tempText = distance)
                  modal4 = true
                "
              >
                <v-list-tile-content class="ml-3">
                  <span
                    v-if="
                      !distance ||
                        distance === undefined ||
                        distance <= 0 ||
                        distance === ''
                    "
                  >
                    -
                  </span>
                  <span v-else> {{ distance }} km </span>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile v-else>
                <v-list-tile-content width="60px">
                  <v-text-field
                    v-model="tempText"
                    type="number"
                    label="km"
                    single-line
                  />
                </v-list-tile-content>
                <div style="min-width:10.5rem">
                  <v-btn
                    class="min-w-0 w-4"
                    @click="
                      distance = tempText
                      reportDetail('distance')
                      modal4 = false
                    "
                  >
                    <v-icon color="primary">
                      fa-check
                    </v-icon>
                  </v-btn>
                  <v-btn class="min-w-0 w-4" @click="modal4 = false">
                    <v-icon color="primary">
                      fa-times
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-tile>
            </v-card>
          </template>
          <!-- ----------------------------------------------------Detaillierte Beschreibung-->
          <template v-if="operation.report['onSite'] !== -1">
            <hr color="#9FA8DA" />
            <v-dialog
              ref="dialog"
              v-model="modal5"
              persistent
              full-width
              style="background-color: white"
            >
              <template v-slot:activator="{ on }">
                <v-card
                  v-on="on"
                  style="padding-bottom:0.4rem"
                  @click="tempText = report"
                >
                  <v-subheader class="nopadding ml-3">
                    {{$t('views.order.order_detail.work_done')}}
                    <span
                      v-show="report.length < reportMin"
                      class="red--text"
                      style="margin-left: 6px"
                    >
                      ({{$t('views.order.order_detail.min_n_characters', {n: reportMin})}})
                    </span>
                  </v-subheader>
                  <v-card-text style="padding-bottom:0; padding-top:0">
                    <p v-show="!extendedWorkflow" class="nomargin fade">
                      {{
                        report.length > 0
                          ? report
                          : $t('views.order.order_detail.detail_desription_of_performed_work')
                      }}
                    </p>
                    <p v-show="extendedWorkflow" class="nomargin">
                      {{
                        report.length > 0
                          ? report
                          : $t('views.order.order_detail.detail_desription_of_performed_work')
                      }}
                    </p>
                  </v-card-text>
                </v-card>
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.detailed_work_description')}}
                </v-card-title>

                <v-card-text>
                  <v-textarea
                    v-model="tempText"
                    name="input-7-1"
                    :label="$t('views.order.order_detail.detailed_technical_description')"
                    :hint="$t('views.order.order_detail.remember_rework')"
                    rows="6"
                    no-resize
                    counter="true"
                  />
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modal5 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      report = tempText
                      reportDetail('report')
                      modal5 = false
                    "
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-if="modal5 && finished" @click="modal5 = false">
                <v-card-title class="headline grey lighten-3" primary-title>
                  {{$t('views.order.order_detail.detailed_work_description')}}
                </v-card-title>

                <v-card-text>
                  <p style="font-size:130%">
                    {{ report }}
                  </p>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-card
              class="ta-center nopadding nomargin"
              flat
              style="height:1.5rem;"
              @click="toggleWorkflow"
            >
              <v-icon
                v-show="!extendedWorkflow"
                style="height:1.4rem;"
                color="primary"
              >
                fa-angle-down
              </v-icon>
              <v-icon
                v-show="extendedWorkflow"
                style="height:1.4rem;"
                color="primary"
              >
                fa-angle-up
              </v-icon>
            </v-card>
          </template>
          <!-- -------------------------------------------------------Lieferungen-->
          <template v-if="testing">
            <v-list-tile
              v-for="delivery in operation.deliveries"
              :key="delivery.deliveryNr"
              :to="`/delivery/${operation.id}/${delivery.deliveryNr}`"
            >
              <v-list-tile-action>
                <v-icon color="primary">widgets</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{$t('views.order.order_detail.delivery_bill')}} {{ delivery.deliveryNr }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ delivery.countArticles }} {{$t('views.order.order_detail.article')}}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn icon ripple>
                  <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </template>
          <!-- ---------------------------------------------- NEW-->
          <elmApp v-if="!finished && isNewMaterialAllowed" :flags="{operation, jwt}" />
          <template
            v-if="!finished && sortedParts && operation.report['onSite'] !== -1 && !isNewMaterialAllowed"
          >
            <hr color="#9FA8DA" />
            <v-subheader>
              {{$t('views.order.order_detail.material_overview')}}
            </v-subheader>
            <v-list-tile-sub-title class="ml-2">
              {{$t('views.order.order_detail.from_supplies_and_stock')}}
            </v-list-tile-sub-title>
            <v-card v-for="article in deliveryArticles" :key="article.id">
              <v-divider />
              <!-- --------------- NOT USED -->
              <v-layout>
                <v-flex
                  v-show="article.cardstatus === -1"
                  v-touch="{
                    left: () => (article.cardstatus = 0),
                  }"
                >
                  <v-btn
                    class="min-w-0 w-5 h-5"
                    v-if="!article.fromBin"
                    @click="showArticleDamagedModal(article)"
                  >
                    <div>
                      <v-icon>fa-bug</v-icon>
                      <h6>{{$t('views.order.order_detail.defect')}} /</h6>
                      <h6>DOA</h6>
                    </div>
                  </v-btn>
                  <v-btn
                    class="min-w-0 w-5 h-5"
                    v-if="!article.fromBin"
                    @click="showArticleMissingModal(article)"
                  >
                    <div>
                      <v-icon>fa-question</v-icon>
                      <h6>{{$t('views.order.order_detail.not')}}</h6>
                      <h6>{{$t('views.order.order_detail.arrived')}}</h6>
                    </div>
                  </v-btn>
                  <v-btn
                    class="min-w-0 w-5 h-5"
                    v-if="!article.fromBin"
                    @click="showArticleNotUsedModal(article)"
                  >
                    <div>
                      <v-icon style="padding-left:6.25px;padding-right:6.25px">
                        fa-times
                      </v-icon>
                      <h6>{{$t('views.order.order_detail.not')}}</h6>
                      <h6>{{$t('views.order.order_detail.used')}}</h6>
                    </div>
                  </v-btn>
                  <v-btn
                    class="min-w-0 w-5 h-5"
                    v-if="article.fromBin"
                    @click="removeMaterialFromWarehouse(article)"
                  >
                    <div>
                      <v-icon style="padding-left:6.25px;padding-right:6.25px">
                        fa-trash
                      </v-icon>
                      <h6>{{$t('views.order.order_detail.not')}}</h6>
                      <h6>{{$t('views.order.order_detail.used')}}</h6>
                    </div>
                  </v-btn>
                </v-flex>
                <v-divider vertical />
                <v-flex
                  v-show="article.cardstatus !== 1"
                  shrink
                  :style="{
                    backgroundColor: getArticleColor(article),
                  }"
                  @click="
                    article.cardstatus === 0
                      ? (article.cardstatus = -1)
                      : (article.cardstatus = 0)
                  "
                >
                  <div class="ta-center m-05">
                    <v-icon
                      style="padding-left:6.25px;padding-right:6.25px"
                      x-large
                    >
                      fa-times
                    </v-icon>
                    <div :hidden="article.cardstatus === -1">
                      <v-icon>
                        fa-long-arrow-alt-left
                      </v-icon>
                    </div>
                    <div :hidden="article.cardstatus !== -1">
                      <v-icon>
                        fa-long-arrow-alt-right
                      </v-icon>
                    </div>
                  </div>
                </v-flex>
                <!-- --------------- INFO -->
                <template v-if="article.cardstatus === 0">
                  <v-divider vertical />
                  <v-flex
                    :style="{
                      backgroundColor: getArticleColor(article),
                    }"
                    v-touch="{
                      left: () => (article.cardstatus = 1),
                      right: () => (article.cardstatus = -1),
                    }"
                    grow
                  >
                    <div>
                      <v-subheader class="nopadding nomargin h-1 ml-2 mt-1">
                        <v-spacer />
                        {{ article.fromBin ? 'Vom Lager' : 'Aus Lieferung' }}
                      </v-subheader>
                      <v-subheader class="nopadding nomargin h-1 ml-2 mt-1">
                        {{ article.template.description }}
                      </v-subheader>
                      <v-subheader class="nopadding nomargin h-1 ml-2 mt-1">
                        {{ article.itemNr }}
                      </v-subheader>
                      <v-subheader class="nopadding nomargin h-1 ml-2 mt-1">
                        SN: {{ article.sn }}
                        <v-spacer />
                        {{ article.reportStatus }}
                      </v-subheader>
                    </div>
                  </v-flex>
                  <v-divider vertical />
                </template>
                <!-- --------------- USED -->
                <v-flex
                  v-show="article.cardstatus !== -1"
                  shrink
                  :style="{
                    backgroundColor: getArticleColor(article),
                  }"
                  @click="
                    article.cardstatus === 0
                      ? (article.cardstatus = 1)
                      : (article.cardstatus = 0)
                  "
                >
                  <div class="ta-center m-05">
                    <v-icon x-large>
                      fa-check
                    </v-icon>
                    <div :hidden="article.cardstatus === 1">
                      <v-icon>
                        fa-long-arrow-alt-right
                      </v-icon>
                    </div>
                    <div :hidden="article.cardstatus !== 1">
                      <v-icon>
                        fa-long-arrow-alt-left
                      </v-icon>
                    </div>
                  </div>
                </v-flex>
                <v-divider vertical />
                <v-flex
                  v-show="article.cardstatus === 1"
                  v-touch="{
                    right: () => (article.cardstatus = 0),
                  }"
                >
                  <v-btn
                    class="min-w-0 w-5 h-5"
                    @click="setMaterialUsed(article)"
                  >
                    <div>
                      <v-icon>fa-check</v-icon>
                      <h6>{{$t('views.order.order_detail.installed')}}</h6>
                    </div>
                  </v-btn>
                  <v-btn
                    class="min-w-0 w-5 h-5"
                    @click="showReplaceArticleModal(article)"
                  >
                    <div>
                      <v-icon>fa-check-double</v-icon>
                      <h6>{{$t('views.order.order_detail.installed')}} &</h6>
                      <h6>{{$t('views.order.order_detail.exchanged')}}</h6>
                    </div>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
            <v-dialog v-model="modalArticleDamaged" persistent lazy full-width>
              <v-card v-if="modalArticleDamaged">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.article_id_defect')}}
                </v-card-title>
                <v-card-text>
                  {{$t('views.order.order_detail.article_is_marked_defect_for_retour')}}
                  <v-text-field
                    v-model="articleSN"
                    :label="$t('views.order.order_detail.article_serial_number')"
                    :rules="[rules.required, rules.min]"
                    :disabled="tempArticle.sn !== ''"
                  />
                  <v-text-field
                    v-model="articleError"
                    :label="$t('views.order.order_detail.error_description')"
                    :rules="[rules.required, rules.min]"
                  />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    flat
                    color="primary"
                    @click="modalArticleDamaged = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    :disabled="
                      !articleSN ||
                        articleSN.length < 3 ||
                        !articleError ||
                        articleError.length < 3
                    "
                    @click="
                      setMaterialDOA(tempArticle, articleSN)
                      modalArticleDamaged = false
                    "
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="modalArticleNotUsed" persistent lazy full-width>
              <v-card v-if="modalArticleNotUsed">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.article_not_used')}}
                </v-card-title>
                <v-card-text>
                  {{$t('views.order.order_detail.article_will_be_added_to_stock')}}
                </v-card-text>
                <v-text-field
                  v-model="articleSN"
                  :label="$t('views.order.order_detail.article_serial_number')"
                  :rules="[rules.required, rules.min]"
                  :disabled="tempArticle.sn !== ''"
                />
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    flat
                    color="primary"
                    @click="modalArticleNotUsed = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    :disabled="!articleSN || articleSN.length < 3"
                    @click="
                      setMaterialNotUsed(tempArticle, articleSN)
                      modalArticleNotUsed = false
                    "
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="modalArticleMissing" persistent lazy full-width>
              <v-card v-if="modalArticleMissing">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.article_missing')}}
                </v-card-title>
                <v-card-text>
                  {{$t('views.order.order_detail.article_was_not_included_in_delivery')}}
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    flat
                    color="primary"
                    @click="modalArticleMissing = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      setMaterialNotInPackage(tempArticle)
                      modalArticleMissing = false
                    "
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="modalChangePart" persistent lazy full-width>
              <v-card v-if="modalChangePart">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.capture_removed_part')}}
                </v-card-title>
                <v-card-text>
                  <!--                  <v-text-field-->
                  <!--                    v-model="changePartType"-->
                  <!--                    label="Bezeichnung"-->
                  <!--                    disabled="true"-->
                  <!--                  />-->
                  <!--                  <v-text-field-->
                  <!--                    v-model="changePartBuild"-->
                  <!--                    label="Modell"-->
                  <!--                    :disabled="!changeModell"-->
                  <!--                  />-->
                  <!--                  <list-tile-sub-title-->
                  <!--                    class="ml-4"-->
                  <!--                    @click="changeModell ? changeModell = false : changeModell = true"-->
                  <!--                  >-->
                  <!--                    Modell ändern-->
                  <!--                  </list-tile-sub-title>-->
                  <!--                  <input-->
                  <!--                    v-model="changeModell"-->
                  <!--                    class="ml-2"-->
                  <!--                    type="checkbox"-->
                  <!--                    value="changeModell"-->
                  <!--                  />-->
                  <v-autocomplete
                    v-model="changeTemplateId"
                    :items="project.templates"
                    item-value="id"
                    item-text="description"
                    label="Typ"
                    persistent-hint
                  ></v-autocomplete>
                  <v-text-field
                    v-model="articleSN"
                    :label="$t('views.order.order_detail.serial_number_installation')"
                    :rules="[rules.required, rules.min]"
                    :disabled="changeMaterial.sn !== ''"
                  />
                  <v-text-field
                    v-model="changePartSn"
                    :label="$t('views.order.order_detail.serial_number_deinstallation')"
                    :hint="$t('views.order.order_detail.unconditionally')"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    v-model="changePartDescription"
                    :label="$t('views.order.order_detail.error_description')"
                    :rules="[rules.required, rules.min]"
                  />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn flat color="primary" @click="modalChangePart = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      replaceMaterial(changeMaterial, articleSN)
                      modalChangePart = false
                    "
                    :disabled="
                      (changePartSn ? changePartSn.length < 1 : true) ||
                        (articleSN ? articleSN.length < 3 : true) ||
                        (changePartDescription
                          ? changePartDescription.length < 4
                          : true)
                    "
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="modalNewPart2" persistent lazy fullscreen>
              <v-card v-if="modalNewPart2">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.choose_article')}}
                </v-card-title>
                <v-list two-line dense style="overflow-y:auto;height:70vh">
                  <v-card-title class="nopadding nomargin">
                    <v-spacer />
                    <v-text-field
                      v-model="search"
                      class="nopadding nomargin"
                      prepend-icon="search"
                      label="Search"
                      single-line
                      hide-details
                    />
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="cleaned"
                    :search="search"
                    :custom-filter="materialSearch"
                    expand
                    item-key="description"
                  >
                    <template v-slot:items="props">
                      <tr
                        :style="
                          props.item.count > 1
                            ? ''
                            : props.item.selected === true
                            ? 'backgroundColor:#AED581'
                            : ''
                        "
                        @click="
                          props.item.count > 1
                            ? (props.expanded = !props.expanded)
                            : (props.item.selected = !props.item.selected)
                        "
                      >
                        <td>
                          <tr>
                            {{ props.item.description }}
                          </tr>
                          <tr v-if="props.item.count === 1">
                            SN:{{ props.item.sn }}
                          </tr>
                          <tr v-else style="background-color:#DDDDDD">
                            <template v-if="props.expanded">
                              <v-icon small>fa-angle-up</v-icon>
                              {{$t('views.order.order_detail.click_to_collapse')}}
                            </template>
                            <template v-else>
                              <v-icon small>fa-angle-down</v-icon>
                              {{$t('views.order.order_detail.click_to_expand')}}
                            </template>
                          </tr>
                        </td>

                        <td>{{ props.item.count }}</td>
                      </tr>
                    </template>
                    <template v-slot:expand="props">
                      <v-list class="ml-4" flat>
                        <v-card
                          v-for="temp in showSNs(props.item.description)"
                          :key="temp.id"
                          class="nopadding nomargin h-2"
                          :style="
                            temp.selected === true
                              ? 'backgroundColor:#AED581'
                              : ''
                          "
                          @click="temp.selected = !temp.selected"
                        >
                          <v-card-title class="nopadding nomargin h-2 ml-2">
                            SN: {{ temp.sn }}
                          </v-card-title>
                        </v-card>
                      </v-list>
                      <hr />
                    </template>
                  </v-data-table>
                </v-list>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      getData()
                      modalNewPart2 = false
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      addMaterialFromWarehouse()
                      modalNewPart2 = false
                    "
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- -----------------------------------------------------Gewechselte Teile-->
            <v-list v-if="sortedParts.length !== 0" class="nopadding">
              <v-list-tile-sub-title class="ml-2 mt-2">
                {{$t('views.order.order_detail.captured_with_rma')}}
              </v-list-tile-sub-title>
              <v-divider />
              <template v-for="part in sortedParts">
                <v-list-tile :key="part.rma" @click="showPartModal(part)">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ part.rma }}
                      <span class="gray ml10 fs14">
                        {{ part.description }}
                      </span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <span>{{$t('views.order.order_detail.old_sn')}}: {{ part.oldSN }}</span>
                      <span class="ml-2">{{$t('views.order.order_detail.new_sn')}}: {{ part.newSN }}</span>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider :key="part.rma" />
              </template>
            </v-list>
            <v-dialog v-model="modalPart" persistent lazy full-width>
              <v-card v-if="modalPart">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.exchanged_part')}}
                </v-card-title>
                <v-card-text>
                  <v-flex class="ta-right nomargin nopadding" style="height:0">
                    <v-btn
                      fab
                      class="nomargin nopadding"
                      style="margin-top:-4rem;height:3rem;width:3rem"
                      color="error"
                      :disabled="finished"
                      @click="removePart"
                    >
                      <v-icon>fa-trash-alt</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-text-field
                    v-model="partRMA"
                    label="RMA"
                    :rules="[rules.required, rules.min]"
                    :readonly="finished"
                  />
                  <v-text-field
                    v-model="partOldSn"
                    label="Ausgebaute SN"
                    :hint="$t('views.order.order_detail.unconditionally_if_available')"
                    :readonly="finished"
                  />
                  <v-text-field
                    v-model="partNewSn"
                    label="Eingebaute SN"
                    :hint="$t('views.order.order_detail.unconditionally_if_available')"
                    :readonly="finished"
                  />
                  <v-text-field
                    v-model="partDescription"
                    :label="$t('views.order.order_detail.error_description')"
                    :rules="[rules.required, rules.min]"
                    :readonly="finished"
                  />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modalPart = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    :disabled="
                      (partRMA ? partRMA.length < 4 : true) ||
                        (partDescription ? partDescription.length < 4 : true)
                    "
                    @click="!finished ? updateParts() : (modalPart = false)"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <div>
              <v-btn
                class="nopadding nomargin mb-1"
                color="primary"
                :style="
                  operation.ticket_order_nr.toLowerCase().indexOf('udt') !== -1
                    ? 'width:100%'
                    : 'width:60%'
                "
                @click="
                  groupStorage()
                  modalNewPart2 = true
                "
              >
                <div>
                  <v-icon small class="mr-2">fa-plus</v-icon>
                  {{$t('views.order.order_detail.from_storage')}}
                </div>
              </v-btn>
              <v-btn
                v-if="
                  !(
                    operation.ticket_order_nr.toLowerCase().indexOf('udt') !==
                    -1
                  )
                "
                class="nopadding nomargin mb-1"
                color="#BBBBBB"
                style="width:40%"
                @click="modalNewPart = true"
              >
                <div>
                  {{$t('views.order.order_detail.with_rma')}}
                  <v-icon small class="ml-2">fa-plus</v-icon>
                </div>
              </v-btn>
            </div>
            <v-dialog v-model="modalNewPart" persistent lazy full-width>
              <v-card v-if="modalNewPart">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.add_part')}}
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="newPartRMA"
                    label="RMA"
                    :rules="[rules.required, rules.min]"
                  />
                  <v-text-field
                    v-model="newPartOldSn"
                    :label="$t('views.order.order_detail.old_sn')"
                    :hint="$t('views.order.order_detail.unconditionally_if_available')"
                  />
                  <v-text-field
                    v-model="newPartNewSn"
                    :label="$t('views.order.order_detail.new_sn')"
                    :hint="$t('views.order.order_detail.unconditionally_if_available')"
                  />
                  <v-text-field
                    v-model="newPartDescription"
                    :label="$t('views.order.order_detail.error_description')"
                    :rules="[rules.required, rules.min]"
                  />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn
                    fab
                    class="nomargin nopadding"
                    style="height:2.7rem;width:2.7rem"
                    color="primary"
                    :disabled="
                      (newPartRMA ? newPartRMA.length < 4 : true) ||
                        (newPartDescription
                          ? newPartDescription.length < 4
                          : true)
                    "
                    @click="addPart(), (modalNewPart = true)"
                  >
                    <v-icon small>fa-plus</v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modalNewPart = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    :disabled="
                      (newPartRMA ? newPartRMA.length < 4 : true) ||
                        (newPartDescription
                          ? newPartDescription.length < 4
                          : true)
                    "
                    @click="addPart()"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- -----------------------------------------------------Zusatzfelder-->
          <template
            v-if="testing && sortedFields && operation.report['onSite'] !== -1"
          >
            <hr color="#9FA8DA" />
            <v-list class="nopadding">
              <v-subheader>
                {{$t('views.order.order_detail.additional_fields')}}
              </v-subheader>
              <v-divider />
              <template v-for="field in sortedFields">
                <v-list-tile :key="field.id" @click="showFieldModal(field)">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ field.description }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ field.value ? field.value : '-' }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider :key="field.id" />
              </template>
            </v-list>
            <v-dialog
              v-model="modalField"
              persistent
              lazy
              full-width
              width="100%"
            >
              <v-card v-if="modalField">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{ fieldDescription }}
                </v-card-title>
                <v-card-text>
                  <v-text-field v-model="fieldValue" single-line />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modalField = false">
                    Cancel
                  </v-btn>
                  <v-btn flat color="primary" @click="updateField()">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- -----------------------------------------------------Zusatzfelder-->
          <template
            v-if="
              sortedFields &&
                sortedFields.length > 0 &&
                operation.report['onSite'] !== -1
            "
          >
            <hr color="#9FA8DA" />
            <v-list class="nopadding">
              <v-subheader>
                {{$t('views.order.order_detail.additional_fields')}}
              </v-subheader>
              <v-divider />
              <template v-for="field in sortedFields">
                <v-list-tile :key="field.id" @click="showFieldModal(field)">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ field.description }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ field.value ? field.value : '-' }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider :key="field.id" />
              </template>
            </v-list>
            <v-dialog
              v-model="modalField"
              persistent
              lazy
              full-width
              width="100%"
            >
              <v-card v-if="modalField">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{ fieldDescription }}
                </v-card-title>
                <v-card-text>
                  <v-text-field v-model="fieldValue" single-line />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modalField = false">
                    Cancel
                  </v-btn>
                  <v-btn flat color="primary" @click="updateField()">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <!--------------------------------------------------------Geräte -->
          <template v-if="!isNewMaterialAllowed && hasChecklist && operation.report['onSite'] !== -1">
            <v-list>
              <v-subheader>
                {{$t('views.order.order_detail.devices_checklist')}}
              </v-subheader>
              <DeviceList
                v-for="device in devices"
                :key="device.id"
                :device="device"
              />
            </v-list>
          </template>
          <template v-else-if="isChecklistLoading">
            <div class="spinner-center" style="height:5rem">
              <v-progress-circular
                indeterminate
                size="40"
                color="primary"
                width="4"
              />
            </div>
          </template>

          <!-- -------------------------------------------PLATZHALTER---Zusatzfelder-->
          <template v-if="operation.address.company">
            <template
              v-if="
                operation.report['onSite'] !== -1 &&
                  testing &&
                  operation.address.company.toLowerCase().indexOf('uni') === -1
              "
            >
              <hr color="#9FA8DA" />
              <v-dialog ref="dialog" v-model="modal3" persistent full-width>
                <v-list-tile
                  slot="activator"
                  @click="selectedValues = selectedExtendedValues"
                >
                  <v-list-tile-content>
                    <v-list-tile-sub-title>
                      {{$t('views.order.order_detail.additional_fields')}}
                      <span
                        v-show="selectedExtendedValues.length === 0"
                        class="red--text"
                        style="margin-left: 6px"
                      >
                        ({{$t('views.order.order_detail.incomplete')}})
                      </span>
                    </v-list-tile-sub-title>
                    <v-list-tile-title
                      v-show="selectedExtendedValues.length === 0"
                      class="ml-3"
                    >
                      -
                    </v-list-tile-title>
                    <v-list-tile-title
                      v-show="selectedExtendedValues.length === 1"
                      class="ml-3"
                    >
                      {{ selectedExtendedValues.length }} {{$t('views.order.order_detail.field_selected')}}
                    </v-list-tile-title>
                    <v-list-tile-title
                      v-show="selectedExtendedValues.length > 1"
                      class="ml-3"
                    >
                      {{ selectedExtendedValues.length }} {{$t('views.order.order_detail.fields_selected')}}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-card v-show="modal3">
                  <v-card-title class="headline grey lighten-2" primary-title>
                    {{ operation.address.company }}-Felder
                  </v-card-title>
                  <v-card-text>
                    <v-checkbox
                      v-for="(extVal, i) in extendedValues"
                      :key="extVal"
                      v-model="selectedValues"
                      :label="extVal"
                      :value="i"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn flat color="primary" @click="modal3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      flat
                      color="primary"
                      @click="
                        modal3 = false
                        selectedExtendedValues = selectedValues
                      "
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!-- ---------------------------------------PLATZHALTER--UniData-Felder-->
            <template
              v-if="
                operation.report['onSite'] !== -1 &&
                  operation.address.company.toLowerCase().indexOf('uni') !==
                    -1 &&
                  testing
              "
            >
              <hr color="#9FA8DA" />
              <v-dialog ref="dialog" v-model="modal6" persistent full-width>
                <v-list-tile
                  slot="activator"
                  @click="selectedValues = selectedUniDataValues"
                >
                  <v-list-tile-content>
                    <v-list-tile-sub-title>
                      {{$t('views.order.order_detail.uni_data_fields')}}
                      <span
                        v-show="selectedUniDataValues.length === 0"
                        class="red--text"
                        style="margin-left: 6px"
                      >
                        ({{$t('views.order.order_detail.incomplete')}})
                      </span>
                    </v-list-tile-sub-title>
                    <v-list-tile-title
                      v-show="selectedUniDataValues.length === 0"
                      class="ml-3"
                    >
                      -
                    </v-list-tile-title>
                    <v-list-tile-title
                      v-show="
                        selectedUniDataValues.length === 1 &&
                          selectedUniDataValues[0] === noInput
                      "
                      class="ml-3"
                    >
                      {{ selectedUniDataValues[0] }}
                    </v-list-tile-title>
                    <v-list-tile-title
                      v-show="
                        selectedUniDataValues.length === 1 &&
                          selectedUniDataValues[0] !== noInput
                      "
                      class="ml-3"
                    >
                      {{ uniDataValues[selectedUniDataValues[0]] }}
                    </v-list-tile-title>
                    <v-list-tile-title
                      v-show="selectedUniDataValues.length > 1"
                      class="ml-3"
                    >
                      {{ selectedUniDataValues.length }} {{$t('views.order.order_detail.fields_selected')}}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-card v-show="modal6">
                  <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('views.order.order_detail.uni_data_fields')}}
                  </v-card-title>
                  <v-card-text>
                    <v-checkbox
                      :key="noInput"
                      v-model="selectedValues"
                      :label="noInput"
                      :value="noInput"
                    />
                    <v-checkbox
                      v-for="(uniData, i) in uniDataValues"
                      :key="uniData"
                      v-model="selectedValues"
                      :label="uniData"
                      :value="i"
                      :disabled="selectedValues.includes(noInput)"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn flat color="primary" @click="modal6 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      flat
                      color="primary"
                      @click="
                        modal6 = false
                        if (selectedValues.includes(noInput)) {
                          selectedValues = [noInput]
                        }
                        selectedUniDataValues = selectedValues
                      "
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </template>
          <!--------------------------------------------------------------CQC----->
          <template v-if="cqc && cqcValues && cqcValues.length > 0">
            <hr color="#9FA8DA" />
            <v-dialog
              ref="dialog"
              v-model="modal6"
              persistent
              lazy
              full-width
              width="100%"
            >
              <div slot="activator" @click="cqc_temp = cqc">
                <v-subheader>
                  {{$t('views.order.order_detail.uni_data_fields')}}
                  <span
                    v-if="cqc.length === 0"
                    class="red--text"
                    style="margin-left: 6px"
                  >
                    ({{$t('views.order.order_detail.incomplete')}})
                  </span>
                </v-subheader>
                <v-list dense>
                  <v-list-tile v-if="cqc.length === 0">
                    <v-list-tile-content>
                      <v-list-tile-title class="ml-2">
                        -
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    v-for="uniDataIndex in cqc"
                    :key="uniDataIndex.id"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title class="ml-2">
                        -{{ cqcValues[uniDataIndex - 1].description }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </div>
              <v-card v-if="modal6">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{$t('views.order.order_detail.uni_data_fields')}}
                </v-card-title>
                <v-card-text>
                  <div v-for="uniData in cqcValues" :key="uniData">
                    <v-checkbox
                      v-model="cqc_temp"
                      :label="uniData.description"
                      :value="uniData.id"
                      :disabled="cqc_temp.includes(1) && uniData.id !== 1"
                    />
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modal6 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      cqc_temp.includes(1) ? (cqc = [1]) : (cqc = cqc_temp)
                      reportDetail('cqc')
                      modal6 = false
                    "
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!------------------------------------------------------Material-UNI_DATA---->
          <!--          <template v-if="testing">-->
          <!--            <hr color="#9FA8DA" />-->
          <!--            <v-list-->
          <!--              v-if="operation && operation.report && operation.report.materials"-->
          <!--            >-->
          <!--              <v-subheader>Material</v-subheader>-->
          <!--              <v-list-tile-->
          <!--                v-for="material in operation.report.materials"-->
          <!--                :key="material.id"-->
          <!--                class="mb-3"-->
          <!--              >-->
          <!--                <v-list-tile-content>-->
          <!--                  <v-list-tile-title>-->
          <!--                    {{ material.itemNr }}-->
          <!--                    <span class="gray ml10 fs14">-->
          <!--                      {{ material.status }}-->
          <!--                      <span class="ml6 mr6">-</span>-->
          <!--                      {{ material.reportStatus }}-->
          <!--                    </span>-->
          <!--                  </v-list-tile-title>-->
          <!--                  <v-list-tile-sub-title>-->
          <!--                    <span class="textBlack">{{ material.description }}</span>-->
          <!--                    <span v-if="material.amount > 1" class="gray ml10 fs14">-->
          <!--                      {{ material.amount }} Artikel-->
          <!--                    </span>-->
          <!--                    <span v-if="material.amount == 1" class="gray ml10 fs14">-->
          <!--                      SN: {{ material.sn }}-->
          <!--                    </span>-->
          <!--                  </v-list-tile-sub-title>-->
          <!--                </v-list-tile-content>-->
          <!--                <v-list-tile-action>-->
          <!--                  <v-btn icon ripple small @click="showMaterialModal(material)">-->
          <!--                    <v-icon-->
          <!--                      :color="material.reportStatus ? 'grey lighten-1' : 'red'"-->
          <!--                    >-->
          <!--                      edit-->
          <!--                    </v-icon>-->
          <!--                  </v-btn>-->
          <!--                </v-list-tile-action>-->
          <!--              </v-list-tile>-->
          <!--            </v-list>-->
          <!--            <v-dialog v-model="modal7" persistent lazy full-width width="100%">-->
          <!--              <v-card v-if="modal7">-->
          <!--                <v-card-title class="headline grey lighten-2" primary-title>-->
          <!--                  Materialverbrauch-->
          <!--                </v-card-title>-->
          <!--                <v-card-text>-->
          <!--                  <v-radio-group v-model="materialReportStatus">-->
          <!--                    <v-radio label="Material wurde verwendet" value="Used" />-->
          <!--                    <v-radio label="Nicht verwendet" value="NotUsed" />-->
          <!--                    <v-radio label="Defekt (DOA)" value="DOA" />-->
          <!--                  </v-radio-group>-->
          <!--                </v-card-text>-->
          <!--                <v-divider />-->
          <!--                <v-card-actions>-->
          <!--                  <v-spacer />-->
          <!--                  <v-btn flat color="primary" @click="modal7 = false">-->
          <!--                    Cancel-->
          <!--                  </v-btn>-->
          <!--                  <v-btn flat color="primary" @click="updateMaterial()">-->
          <!--                    OK-->
          <!--                  </v-btn>-->
          <!--                </v-card-actions>-->
          <!--              </v-card>-->
          <!--            </v-dialog>-->
          <!--          </template>-->
          <!-- ----------------------------------------------------GEO LOCATION TEST-->
          <!--          <template>-->
          <!--            <hr color="#9FA8DA" />-->
          <!--            <h3>Hier könnte Ihre Werbung stehen:</h3>-->
          <!--            <h4 v-if="!tried">{{ werbung }}</h4>-->
          <!--            <h4 v-else="tried">{{ werbung_pos }}</h4>-->
          <!--            <v-btn-->
          <!--              @click="tryGPS()"-->
          <!--            />-->
          <!--          </template>-->
          <!-- ----------------------------------------------------Instandsetzerbericht-->
          <template
            v-if="cqc && cqcValues && cqcValues.length > 0 && cqc.includes(5)"
          >
            <hr color="#9FA8DA" />
            <v-list style="background-color: #EF9A9A">
              <h3 style="margin-left: 6px">
                {{$t('views.order.order_detail.repair_report_reqiured')}}
              </h3>
              <v-subheader>
                {{$t('views.order.order_detail.upload_photo_of_repair_report')}}
              </v-subheader>
            </v-list>
          </template>
          <!-- ----------------------------------------------------Fotos-->
          <template v-if="operation.report['onSite'] !== -1 && !(hasChecklist || hasStations)">
            <hr color="#9FA8DA" />
            <v-list>
              <v-subheader>
                {{$t('views.order.order_detail.photo_from_work_report')}}
                <span
                  v-if="filesCount === 0"
                  class="red--text"
                  style="margin-left: 6px"
                >
                  ({{$t('views.order.order_detail.incomplete')}})
                </span>
              </v-subheader>
              <FileUpload @complete="completeFileUpload()" />
            </v-list>
          </template>
          <!-- ----------------------------------------------------DATEIEN-->
          <ul
            v-if="operation && operation.report && operation.report.files"
            class="mt-3"
          >
            <li v-for="file in operation.report.files" :key="file.name">
              <a :href="file.url" target="_blank">{{ file.name }}</a>
              <span class="ml-3 mr-3 grey--text">-</span>
              <a href="#" @click.stop.prevent="deleteFile(file.name)">
                {{$t('views.order.order_detail.delete')}}
              </a>
            </li>
          </ul>
          <!-- -------------------------------------------------------ProgressBar2-->
          <!--600000===10 Minuten - Kulanzzeit für SLA-->
          <v-progress-linear
            v-if="operation.report['onSite'] !== -1"
            class="nopadding nomargin"
            :color="getProgressColor()"
            height="1.6rem"
            :value="
              operation.report['finished'] !== -1
                ? 100
                : operation.report['repaired'] !== -1
                ? 100
                : operation.report['onSite'] !== -1
                ? 40
                : operation.report['travelStart'] !== -1
                ? 15
                : 1
            "
          >
            <h3 v-if="operation.report['travelStart'] !== -1" class="ta-center">
              <template v-if="operation.report['finished'] !== -1">
                <template
                  v-if="
                    kulanzSLA === -1 ||
                      kulanzSLA - operation.report['finished'] >= -600000
                  "
                >
                  {{$t('views.order.order_detail.completed_within_sla')}}
                </template>
                <template v-else>
                  {{$t('views.order.order_detail.completed_within_sla')}}
                </template>
              </template>
              <template v-else-if="operation.report['repaired'] !== -1">
                {{$t('views.order.order_detail.done')}}
              </template>
              <template v-else-if="operation.report['onSite'] !== -1">
                Todo: {{$t('views.order.order_detail.finish_repair')}}
              </template>
              <template v-else>
                Todo: {{$t('views.order.order_detail.arrive_at_the_operation')}}
              </template>
            </h3>
          </v-progress-linear>
          <!-- ----------------------------------------------------Needed-->
          <template v-if="operation.report['onSite'] !== -1">
            <hr color="#9FA8DA" />
            <h3 class="ml-2">
              {{$t('views.order.order_detail.complete_work_report')}}
            </h3>
            <v-card
              v-show="
                (operation.report['onSite'] !== -1 &&
                  ((distance ? distance <= 0 : !distance) ||
                    (report.length < reportMin && !(hasChecklist || hasStations)) ||
                    (filesCount === 0 && !(hasChecklist || hasStations)) ||
                    material_blocked ||
                    (cqc &&
                      cqc.length === 0 &&
                      cqcValues &&
                      cqcValues.length > 0))) ||
                  !checkDevicesDone() ||
                  !stationsDone
              "
            >
              <v-card-title class="nopadding ml-2">
                <h4 class="red--text">{{$t('views.order.order_detail.still_required_fields')}}:</h4>
              </v-card-title>
              <v-card-text class="nopadding ml-4">
                <ul>
                  <li v-show="(!isNewMaterialAllowed && !checkDevicesDone())">
                    {{$t('views.order.order_detail.devices_checklist')}}
                  </li>
                  <li v-show="distance ? distance <= 0 : !distance">
                    {{$t('views.order.order_detail.distance')}}
                  </li>
                  <li v-show="report.length < reportMin && !(hasChecklist || hasStations)">
                    {{$t('views.order.order_detail.work_done_n', {n: reportMin - report.length})}}
                  </li>
                  <li v-show="filesCount === 0 && !(hasChecklist || hasStations)">{{$t('views.order.order_detail.photos')}}</li>
                  <li v-show="!stationsDone">{{$t('views.order.order_detail.stations')}}</li>
                  <template v-for="article in deliveryArticles">
                    <li v-if="article.reportStatus === '' && !hasStations" :key="article.id">
                      {{$t('views.order.order_detail.material')}}: {{ article.template.description }}
                    </li>
                  </template>
                  <li
                    v-if="
                      cqc &&
                        cqc.length === 0 &&
                        cqcValues &&
                        cqcValues.length > 0
                    "
                  >
                    {{$t('views.order.order_detail.uni_data_fields')}}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </template>
        </v-list>
        <v-btn
          v-if="operation.report['onSite'] !== -1"
          block
          color="primary"
          :disabled="
            operation.report['onSite'] === -1 ||
              (distance ? distance <= 0 : !distance) ||
              (report.length < reportMin && !(hasChecklist || hasStations)) ||
              (filesCount === 0 && !(hasChecklist || hasStations)) ||
              finished ||
              material_blocked ||
              (cqc && cqc.length === 0 && cqcValues && cqcValues.length > 0) ||
              (!isNewMaterialAllowed && !checkDevicesDone()) ||
              !stationsDone
          "
          @click="
            checkCQC()
            finalize = true
          "
        >
          {{$t('views.order.order_detail.complete_work_report')}}
        </v-btn>
        <v-dialog ref="dialog" v-model="finalize">
          <div style="position: absolute;left: calc(100% - 24px);transform: translate(-100%, 0);z-index: 1;">
            <geolocationChecker ref="geolocationChecker"/>
          </div>
          <v-card v-if="finalize && !showSignaturePanel">
            <v-card-title class="headline grey lighten-2" primary-title>
              {{$t('views.order.order_detail.are_you_sure')}}
            </v-card-title>
            <v-card-text>
              <p style="font-size:120%">
                <b>
                  {{$t('views.order.order_detail.after_sending_the_editing_options_are_blocked')}}
                </b>
              </p>
            </v-card-text>

            <!-- -------------------------------------------------------Status der Arbeiten-->
            <v-container
              class="nomargin nopadding mb-4"
              style="padding-left:1.5rem;padding-right:1.5rem"
            >
              <v-layout class="nopadding nomargin">
                <v-flex @click=";(status = 0), reportDetail('status')">
                  <v-layout>
                    <v-flex shrink>
                      <input
                        v-model="status"
                        class="mr-2"
                        type="radio"
                        value="0"
                      />
                    </v-flex>
                    <v-flex>
                      <label>{{$t('views.order.order_detail.rework_necessary')}}</label>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex @click=";(status = 1), reportDetail('status')">
                  <v-layout>
                    <v-flex shrink>
                      <input
                        v-model="status"
                        class="mr-2"
                        type="radio"
                        value="1"
                      />
                    </v-flex>
                    <v-flex>
                      <label>{{$t('views.order.order_detail.work_successfully_completed')}}</label>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
            <v-layout
              v-if="status === 0"
              class="nopadding mb-4"
              style="height:8rem"
            >
              <v-flex shrink>
                <ul style="list-style-type:none">
                  <li
                    class="pt-2"
                    @click="
                      blame = 'tec'
                      blameDetailReason = undefined
                      blameText = ''
                    "
                  >
                    <input
                      v-model="blame"
                      class="mr-2"
                      type="radio"
                      value="tec"
                    />
                    <label>{{$t('views.order.order_detail.technician')}}</label>
                  </li>
                  <li
                    class="pt-2"
                    @click="
                      blame = 'cus'
                      blameDetailReason = undefined
                      blameText = ''
                    "
                  >
                    <input
                      v-model="blame"
                      class="mr-2"
                      type="radio"
                      value="cus"
                    />
                    <label>{{$t('views.order.order_detail.customer')}}</label>
                  </li>
                  <li
                    class="pt-2"
                    @click="
                      blame = 'mat'
                      blameDetailReason = undefined
                      blameText = ''
                    "
                  >
                    <input
                      v-model="blame"
                      class="mr-2"
                      type="radio"
                      value="mat"
                    />
                    <label>{{$t('views.order.order_detail.material')}}</label>
                  </li>
                </ul>
              </v-flex>
              <v-flex style="max-width: 10rem" grow />
              <v-flex v-if="blame !== '-1'" shrink class="mr-5">
                <v-dialog v-model="blameDetail" width="80%" persistent>
                  <v-btn
                    slot="activator"
                    top
                    class="status-button"
                    @click="tempText = blameDetailReason"
                  >
                    <div
                      :style="
                        blameDetailReason === undefined
                          ? 'margin-bottom:-1.3rem;'
                          : 'margin-bottom:-2.5rem;'
                      "
                    >
                      <v-icon color="primary">fa-tasks</v-icon>
                      <h5>{{$t('views.order.order_detail.reason')}}</h5>
                      <h5 class="ta-center" style="margin-top: 1rem">
                        <template v-if="blameDetailReason !== undefined">
                          {{ blameDetailReason.description }}
                        </template>
                      </h5>
                    </div>
                  </v-btn>
                  <v-card v-show="blameDetail">
                    <v-card-title class="headline grey lighten-2" primary-title>
                      {{$t('views.order.order_detail.reason_for_rework')}}
                    </v-card-title>
                    <v-card-text>
                      <v-radio-group v-model="tempText" column>
                        <template v-if="blame === 'tec'">
                          <v-radio
                            v-for="(reason, i) in blameArrayTechnician"
                            :key="i"
                            :label="reason.description"
                            :value="reason"
                            class="nopadding nomargin mb-3"
                          />
                        </template>
                        <template v-else-if="blame === 'cus'">
                          <v-radio
                            v-for="(reason, i) in blameArrayCustomer"
                            :key="i"
                            :label="reason.description"
                            :value="reason"
                            class="nopadding nomargin mb-3"
                          />
                        </template>
                        <template v-else-if="blame === 'mat'">
                          <v-radio
                            v-for="(reason, i) in blameArraySubstitute"
                            :key="i"
                            :label="reason.description"
                            :value="reason"
                            class="nopadding nomargin mb-3"
                          />
                        </template>
                      </v-radio-group>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-flex shrink>
                        <v-btn
                          flat
                          color="primary"
                          @click="blameDetail = false"
                        >
                          Cancel
                        </v-btn>
                      </v-flex>
                      <v-flex grow />
                      <v-flex shrink>
                        <v-btn
                          flat
                          color="primary"
                          @click="
                            ;(blameText = ''),
                              (blameDetail = false),
                              (blameDetailReason = tempText),
                              (blameText =
                                blameDetailReason.description + ';\n'),
                              (status = 0),
                              reportReworkDetail('status')
                          "
                        >
                          OK
                        </v-btn>
                      </v-flex>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
            <v-layout v-else class="nopadding mb-4" style="height: 8rem">
              <v-flex shrink />
            </v-layout>
            <v-divider />
            <v-card-actions>
              <v-btn flat color="primary" @click="finalize = false">
                Cancel
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="!allSignaturesDone && !hasStations"
                flat
                color="primary"
                :disabled="
                  (status === 0 && blameDetailReason === undefined) ||
                    status === -1
                "
                @click="showSignaturePanel = true"
              >
                {{$t('views.order.order_detail.make_singantures')}}
              </v-btn>
              <v-btn
                v-else
                flat
                color="primary"
                :disabled="
                  (status === 0 && blameDetailReason === undefined) ||
                    status === -1
                "
                @click="
                  finalize = false
                  closeReport()
                  checkForHDD() ? send_email('hdd') : ''
                "
              >
                {{$t('views.order.order_detail.send')}}
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-else-if="allSignaturesDone || hasStations">
            <v-card-title class="headline grey lighten-2" primary-title>
              {{$t('views.order.order_detail.are_you_sure')}}
            </v-card-title>
            <v-card-text>
              <p style="font-size:120%">
                <b>
                  {{$t('views.order.order_detail.after_sending_the_editing_options_are_blocked')}}
                </b>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn flat color="primary" @click="finalize = false">
                Cancel
              </v-btn>

              <v-btn
                flat
                color="primary"
                :disabled="
                  (status === 0 && blameDetailReason === undefined) ||
                    status === -1
                "
                @click="
                  finalize = false
                  closeReport()
                  checkForHDD() ? send_email('hdd') : ''
                  startSync()
                "
              >
                {{$t('views.order.order_detail.send')}}
              </v-btn>
            </v-card-actions>
          </v-card>
          <Signatures
            v-else-if="finalize && showSignaturePanel && !hasStations"
            :close="
              () => {
                finalize = false
              }
            "
          />
        </v-dialog>
        <v-list><v-list-tile /></v-list>
        <!--                          testing random finished-time
        <h4> {{
              format(Date.now()+Math.floor((Math.random() * 600000) + 300000), 'DD.MM.YYYY HH:mm:ss', {
                locale: deLocale,
              })
            }} </h4>
            -->
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en'
import Vue from 'vue'
import FileUpload from '../../components/FileUpload'
import HumanDate from '../../components/Order/HumanDate'
import SlaColorBlock from '../../components/Order/SlaColorBlock'
import { mapGetters } from 'vuex'
import { assureDeviceData } from '../../store/modules/devices'
import DeviceList from '../devices/DeviceList'
import { config, isFeatureAllowed } from '../../config'
import { openSearch, openDirection } from '../../util/maps'
import Signatures from './Signatures'
import sync from '@/sync/sync'
import {ElmComponent, app} from '@/elm/elmComponent'
import axios from 'axios'
import geolocationChecker from '../../components/geolocationChecker'

export default {
  name: 'OrderDetail',
  components: {
    DeviceList,
    FileUpload,
    HumanDate,
    SlaColorBlock,
    Signatures,
    elmApp: ElmComponent(),
    geolocationChecker
  },
  data() {
    return {
      format,
      deLocale,
      date: '',
      time: '',
      logdata: '',
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      modal7: false,
      modal8: false,
      modal9: false,
      finalize: false,
      localReport: this.op,
      signature: null,
      signature2: null,
      signateureOption: {
        penColor: 'rgb(13,71,161)',
        backgroundColor: 'rgb(255,255,255)',
      },
      rep: {},
      distance: null,
      status: -1,
      statusArray: [this.$t('views.order.order_detail.rework_necessary'), this.$t('views.order.order_detail.work_completed')],
      blame: '-1',
      blameDetail: false,
      blameDetailReason: undefined,
      blameText: '',
      blameArray: [this.$t('views.order.order_detail.customer'), this.$t('views.order.order_detail.technician'), this.$t('views.order.order_detail.material')],
      blameArrayTechnician: [
        {
          description: this.$t('views.order.order_detail.missing_defective_equipment'),
          reworkId: 3,
        },
        {
          description: this.$t('views.order.order_detail.missing_documentation'),
          reworkId: 4,
        },
        {
          description: this.$t('views.order.order_detail.missing_know_how'),
          reworkId: 5,
        },
      ],
      blameArraySubstitute: [
        {
          description: this.$t('views.order.order_detail.unsuccessful_without_spare_part'),
          reworkId: 6,
        },
        {
          description: this.$t('views.order.order_detail.defective_spare_part'),
          reworkId: 7,
        },
        {
          description: this.$t('views.order.order_detail.wrong_spare_part'),
          reworkId: 8,
        },
        {
          description: this.$t('views.order.order_detail.no_delivery'),
          reworkId: 9,
        },
      ],
      blameArrayCustomer: [
        {
          description: this.$t('views.order.order_detail.wrong_preliminary_clarification'),
          reworkId: 10,
        },
        {
          description: this.$t('views.order.order_detail.store_closed'),
          reworkId: 11,
        },
        {
          description: this.$t('views.order.order_detail.denial_of_service'),
          reworkId: 12,
        },
        {
          description: this.$t('views.order.order_detail.lack_of_support'),
          reworkId: 1,
        },
        {
          description: this.$t('views.order.order_detail.wrong_responsibility'),
          reworkId: 13,
        },
        {
          description: this.$t('views.order.order_detail.incomplete_assignment'),
          reworkId: 2,
        },
      ],
      reportMin: 20,
      report: '',
      noInput: this.$t('views.order.order_detail.none_of_the_listed_work_performed'),
      selectedValues: [],
      selectedUniDataValues: [],
      uniDataValues: [
        this.$t('views.order.order_detail.reinstallation_done'),
        this.$t('views.order.order_detail.first_fill_reinstallation_done'),
        this.$t('views.order.order_detail.removed_hdd_for_total_berlin'),
        this.$t('views.order.order_detail.completed_repair_report'),
      ],
      selectedExtendedValues: [],
      extendedValues: [
        this.$t('views.order.order_detail.waiting_time_in_minutes'),
        this.$t('views.order.order_detail.waiting_time_reason'),
        this.$t('views.order.order_detail.spent_plasterboard_dowels'),
        this.$t('views.order.order_detail.modification_bracket_switch'),
        this.$t('views.order.order_detail.used_patch_cord'),
        this.$t('views.order.order_detail.number_of_holes_drilled'),
        this.$t('views.order.order_detail.number_of_installed_fireproofings'),
        this.$t('views.order.order_detail.fire_protection_plugs'),
        this.$t('views.order.order_detail.additional_inforamtion'),
        this.$t('views.order.order_detail.units_of_assembly_eqiupment'),
      ],
      cqc: [],
      cqc_temp: [],
      cqcValues: [
        this.$t('views.order.order_detail.none_of_the_mentoined_points_were_carried_out'),
        this.$t('views.order.order_detail.reinstallation_done'),
        this.$t('views.order.order_detail.first_fill_reinstallation_done'),
        this.$t('views.order.order_detail.removed_hdd_for_total_berlin'),
        this.$t('views.order.order_detail.completed_repair_report'),
      ],
      error: {
        travelStart: '',
        onSite: '',
        repaired: '',
        finished: '',
        distance: '',
        status: '',
        report: '',
      },
      operation: {},
      jwt: null,
      baseUrl: config.API,
      materialReportStatus: '',
      materialId: undefined,
      modalField: false,
      sortedFields: [],
      // Daten für Zusatzfeld-Dialog
      fieldId: undefined,
      fieldDescription: '',
      fieldValue: '',
      modalPart: false,
      sortedParts: [],
      // Daten für Part-Dialog
      partRMAOld: undefined, // Falls sich RMA ändert auch die alte RMA speichern
      partRMA: undefined,
      partDescription: '',
      partOldSn: '',
      partNewSn: '',
      modalNewPart: false,
      modalNewPart2: false,
      // Daten für NewPart-Dialog
      newPartRMA: undefined,
      newPartDescription: '',
      newPartOldSn: '',
      newPartNewSn: '',
      rules: {
        required: value => !!value || this.$t('views.order.order_detail.required_field'),
        min: v => (v ? v.length >= 4 || this.$t('views.order.order_detail.at_least_four_characters') : ''),
      },

      deliveryArticles2: [
        {
          itemNr: 'RIE:UPSBATTERY',
          description: this.$t('views.order.order_detail.a_battery'),
          template: {
            description: 'Riello UPS BATTERIES',
            vendor: 'Other',
            productCategory: 'Other',
          },
          status: '',
          amount: 1,
          sn: 'Serial3516',
        },
        {
          itemNr: 'RAS-53061D',
          description: this.$t('views.order.order_detail.printer'),
          template: {
            description: 'RATTA PRINT',
            vendor: 'Other',
            productCategory: 'Other',
          },
          status: '',
          amount: 1,
          sn: 'Serial123',
        },
      ],
      deliveryArticles: [],
      storage: [],
      badParts: [],

      cleaned: [],

      isLoading: true,
      travelTime: '',
      departureTime: 'xx',
      arrivalTime: 'xx',
      repairedTime: 'xx',
      changeTravel: false,
      changeArrival: false,
      changeRepaired: false,
      descr: false,
      extendedDescription: false,
      extendedWorkflow: false,
      kulanzSLA: -1,
      hereUsable: false,
      tempText: '',
      clearNameTec: '',
      clearNameCus: '',
      sign1: false,
      sign2: false,
      tecAccept: false,
      cusAccept: false,
      testing: false,
      finished: false,
      id: 100,
      modalArticleNotUsed: false,
      modalArticleMissing: false,
      modalArticleDamaged: false,
      modalChangePart: false,
      // changePartType: 'Bezeichner, wie Maus/PC',
      // changePartBuild: 'Modell X3000',
      // changeModell: false,
      changeMaterial: undefined, // Das neue Teil was eingebaut wird
      changeTemplateId: undefined,
      changePartSn: '',
      changePartDescription: '',
      tempArticle: {},
      articleError: '',
      articleSN: '',
      saveSelection: [],
      search: '',
      material_blocked: false,
      bugText: '',
      filesCount: -1,
      headers: [
        {
          text: this.$t('views.order.order_detail.stock_article'),
          align: 'left',
          value: 'description',
        },
        {
          text: this.$t('views.order.order_detail.amount'),
          value: 'count',
        },
      ],
      werbung: this.$t('views.order.order_detail.yeah_nice_advertising'),
      werbung_pos: undefined,
      tried: false,
      showSignaturePanel: false,
      isNewMaterialAllowed: false,
      pos_lat:'',
      pos_lon:''
    }
  },
  created() {
    this.getData()
    // this.getLocation()
  },
  mounted() {
  },
  computed: {
    hasDocumentationLink() {
      return (
        this &&
        this.operation &&
        this.operation.additional_documentation &&
        this.operation.additional_documentation[0]
      )
    },
    ...mapGetters(['projects']),
    project() {
      if (!this.operation) return undefined
      return this.projects.find(
        project => project.id === this.operation.projectId
      )
    },
    template() {
      if (!this.changeTemplateId) return undefined
      return this.project.templates.find(
        template => template.id == this.templateId
      )
    },
    devices() {
      return this.$store.state.devices.list
    },
    allSignaturesDone() {
      // This removes the observer from the array
      const signatures = JSON.parse(
        JSON.stringify(this.$store.state.devices.signatures)
      )
      return signatures.reduce((acc, sig) => {
        return (sig.done || sig.queued) && acc
      }, true)
    },
    isChecklistLoading() {
      return this.$store.state.devices.deviceLoadingState === 'LOADING'
    },
    hasChecklist() {
      // This removes the observer from the array
      const devices = JSON.parse(JSON.stringify(this.devices))
      return !!(
        devices &&
        devices.length &&
        this.$store.state.devices.deviceLoadingState === 'SUCCESS' &&
        // Don't show the checklist if the operation has stations
        !this.hasStations
      )
    },
    hasStations() {
      return this.isNewMaterialAllowed
    },
    stationsDone() {
        const done = this.$store.state.stations.done || {}

        if (this.isNewMaterialAllowed) {
            return this.isNewMaterialAllowed && done[this.operation.id] || false
        } else {
            return true
        }

    }
  },

  methods: {
    startSync() {
      sync.start()
    },
    checkNewMaterialAllowed(operationId) {
      const url = "/r/api/v1/operation_metadata/" + operationId + ".json?" + Date.now()
      const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        url,
      };
      axios(options)
        .then(res => {
          if (res && res.data && res.data.workflow_template) {
            this.isNewMaterialAllowed = true
          }
        })
        .catch(err => {
          this.isNewMaterialAllowed = false

          if (err && err.response && (err.response.status == 401 || err.response.status == 403)) {
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/login')
            })
          }
        })
    },
    getData() {
      const orderId = this.$route.params.orderId

      assureDeviceData(this.$store, this.$router, orderId)
      this.checkNewMaterialAllowed(orderId)

      this.$store
        .dispatch('getOperation', orderId)
        .then(operation => {
          this.jwt = this.$store.state.token
          this.operation = operation
          const { report } = operation
          this.report = report.report
          if (this.report === null) this.report = ''
          this.status =
            report.status === 'PLANNED' ? -1 : report.status === 'DONE' ? 1 : 0
          this.distance = report.distance
          operation.report.cqc.sort((a, b) => {
            if (a.sortOrder < b.sortOrder) return 1
            if (a.sortOrder > b.sortOrder) return -1
            return 0
          })
          this.cqcValues = operation.report.cqc
          // initial cqc values
          let cqc = []
          for (let cqcSingle of report.cqc) {
            if (cqcSingle.value) {
              cqc.push(cqcSingle.sortOrder)
            }
          }
          this.cqc = cqc

          if (operation.report.fields) {
            operation.report.fields.sort((a, b) => {
              if (a.sortOrder < b.sortOrder) return 1
              if (a.sortOrder > b.sortOrder) return -1
              return 0
            })
            this.sortedFields = operation.report.fields
          } else {
            this.sortedFields = []
          }

          if (operation.report.files) {
            this.filesCount = operation.report.files.length
          } else {
            this.filesCount = 0
          }

          if (operation.report.parts) {
            operation.report.parts.sort((a, b) => {
              if (a.rma < b.rma) return 1
              if (a.rma > b.rma) return -1
              return 0
            })
            this.sortedParts = operation.report.parts
          } else {
            this.sortedParts = []
          }
          this.material_blocked = false
          if (operation.report.materials) {
            this.deliveryArticles = []
            for (let article of operation.report.materials) {
              Vue.set(article, 'cardstatus', 0)
              Vue.set(article, 'errorText', '')
              // Vue.set(article, 'delivered', true);
              this.deliveryArticles.push(article)
              if (article.reportStatus === '' && !this.hasStations) {
                this.material_blocked = true
              }
            }
            this.deliveryArticles.sort(function(a, b) {
              if (a.description < b.description) {
                return -1
              }
              if (a.description > b.description) {
                return 1
              }
              return 0
            })
          }
          if (operation.report['finished'] !== -1) this.finished = true
          this.$store
            .dispatch('getArticles', {
              warehouseId: operation.warehouseId,
              binId: operation.binId,
              complete: true,
            })
            .then(articles => {
              this.storage = []
              for (let article of articles) {
                if (!article.badPart) {
                  this.storage.push(article)
                }
              }
              this.storage.sort(function(a, b) {
                if (a.description < b.description) {
                  return -1
                }
                if (a.description > b.description) {
                  return 1
                }
                return 0
              })
              this.$store.dispatch('getProjects')
            })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // createArticleList() {
    //   if (this.isLoading && this.operation.report.materials) {
    //     let tempArr2 = []
    //     var n = 0
    //     while (n < 19) {
    //       let storageItem = {
    //         "itemNr": "STOR_ITEM"+n,
    //         "description": "Ein Gegenstand_ im Lager",
    //         "template": {
    //           "description": "STORAGE ITEM"+n,
    //           "vendor": "Other",
    //           "productCategory": "Other"
    //         },
    //         "status": "",
    //         "amount": 1,
    //         "sn": "Stor"+n+n+n
    //       }
    //       storageItem.selected = false
    //       storageItem.cardstatus = 6
    //       storageItem.id = this.id++
    //       n = n + 1
    //       tempArr2.push(storageItem)
    //     }
    //     this.storage = tempArr2
    //   }
    // },
    //  86400000 === 24h
    //  28800000 === 8h
    //  14400000 === 4h
    //   7200000 === 2h
    //   1800000 === 30min

    tryGPS() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.werbung_pos =
            this.$t('views.order.order_detail.point_of_time') +
            format(position.timestamp, 'D. MMM YYYY HH:mm', {
              locale: deLocale,
            }) +
            ', (' +
            position.coords.latitude +
            ', ' +
            position.coords.longitude +
            ')'
        })
      } else {
        this.werbung_pos = 'something failed'
      }
      this.tried = true
    },
    getLocalTime(time) {
      if (this.$i18n.locale == 'en') {
        return format(time, 'h:mmA', {locale: enLocale,})
      } else {
        return format(time, 'HH:mm', {locale: deLocale,}) + 'Uhr'
      }
    },
    getLocalDate(date){
      if (this.$i18n.locale == 'en') {
        return format(date, 'MMM. D, YYYY h:mmA', {locale: enLocale,})
      } else {
        return format(date, 'D. MMM YYYY HH:mm', {locale: deLocale,}) + 'Uhr'
      }
    },
    getProgressColor() {
      var opTime = this.operation.report['finished']
      if (this.operation.sla !== -1) {
        this.kulanzSLA = this.operation.sla
      }
      var sla = this.kulanzSLA
      if (sla !== -1) {
        if (opTime !== -1) {
          if (sla - opTime < 0) {
            return 'red lighten-2'
          }
        } else if (sla - Date.now() < 7200000) {
          return 'orange'
        }
      }
      return 'light-green'
    },
    // addSelectedStorageItems() {
    //   for (let storageItem of this.storage) {
    //     if (storageItem.selected === true) {
    //       if (!(storageItem.cardstatus < 5)) {
    //         storageItem.errorText = ''
    //         storageItem.cardstatus = 0
    //         storageItem.delivered = false
    //         this.deliveryArticles.push(storageItem)
    //       }
    //     }
    //   }
    //   let tempArr = []
    //   for (let article of this.deliveryArticles) {
    //     tempArr.push(article)
    //   }
    //   this.deliveryArticles = tempArr
    // },
    checkCQC() {
      if (this.cqc && this.cqcValues && this.cqcValues.length > 0) {
        if (this.cqc.includes(1)) {
          this.cqc = [1]
          this.reportDetail('cqc')
        }
      }
    },

    //
    // Check if there are any checklist items unaccounted for
    // NOTE: For a brief moment, this is true, even though it is not really done.
    //       This is due to the API taking a moment to send over the data!
    //       Since not all "orders" get the job checklist, we cannot simply assume
    //       that, when the list is empty, this precondition fails.
    //
    checkDevicesDone() {
      return this.devices.length
        ? this.devices.reduce((isDone, device) => {
            // We need to filter out
            const jobs = device.jobs.filter(
              job => job.job_type !== 'checklist_info'
            )
            return (
              isDone &&
              jobs.length ===
                jobs.reduce(
                  //
                  // When an upload is in progress we still want to be able to show the overlay to
                  // show the technician a list of files that are not uploaded yet
                  //
                  (acc, job) => (job.done || job.queued ? acc + 1 : acc),
                  0
                )
            )
          }, true)
        : true
    },

    deleteArticle(delArticle) {
      let tempArr = []
      for (let article of this.deliveryArticles) {
        if (article.id !== delArticle.id) {
          tempArr.push(article)
        }
      }
      this.deliveryArticles = tempArr
      for (let article of this.storage) {
        if (article.id === delArticle.id) {
          article.cardstatus = 6
          article.selected = false
        }
      }
    },
    groupStorage() {
      let known = []
      let collector = []
      let cleaned = []
      for (let item of this.storage) {
        if (!known.includes(item.description)) {
          known.push(item.description)
          collector[item.description] = 1
        } else {
          collector[item.description]++
        }
      }
      for (var i = 0; i < known.length; i++) {
        if (collector[known[i]] > 1) {
          cleaned.push(known[i])
        }
      }
      known = cleaned
      cleaned = []
      let added = []
      for (let item of this.storage) {
        if (!known.includes(item.description)) {
          item.count = 1
          cleaned.push(item)
        } else if (!added.includes(item.description)) {
          item.count = collector[item.description]
          cleaned.push(item)
          added.push(item.description)
        }
      }
      this.cleaned = cleaned
    },
    showSNs(description) {
      let sn = []
      for (let article of this.storage) {
        if (article.description === description) {
          sn.push(article)
        }
      }
      return sn
    },
    showArticleDamagedModal(article) {
      this.tempArticle = article
      this.articleError = article.errorText
      if (!article.sn) this.articleSN = ''
      else this.articleSN = article.sn
      this.modalArticleDamaged = true
    },
    showArticleMissingModal(article) {
      this.tempArticle = article
      this.modalArticleMissing = true
    },
    showArticleNotUsedModal(article) {
      this.tempArticle = article
      if (!article.sn) this.articleSN = ''
      else this.articleSN = article.sn
      this.modalArticleNotUsed = true
    },
    showReplaceArticleModal(article) {
      this.changeMaterial = article
      this.changeTemplateId = article.template.id
      if (!article.sn) this.articleSN = ''
      else this.articleSN = article.sn
      this.modalChangePart = true
    },
    getArticleColor(article) {
      switch (article.reportStatus) {
        case 'DOA':
          return '#EF9A9A'
        case 'Missing':
          return '#EF9A9A'
        case 'NotUsed':
          return '#EF9A9A'
        case 'Used':
          return '#C5E1A5'
        default:
          return 'white'
      }
    },
    toggleDescription() {
      if (this.extendedDescription) {
        this.extendedDescription = false
      } else {
        this.extendedDescription = true
      }
    },
    toggleWorkflow() {
      if (this.extendedWorkflow) {
        this.extendedWorkflow = false
      } else {
        this.extendedWorkflow = true
      }
    },
    backToOrders() {
      this.$router.push('/orders')
    },
    reportTime(type) {
      let ms = 1000 * 60 * 1 //let ms = 1000 * 60 * 15
      this.logdata += 'reportTime(' + type + ')<br>'
      if (this.operation.ticket_order_nr.toLowerCase().indexOf('udt') !== -1) {
        return new Promise(resolve => {
          const operationId = this.operation.id
          this.$store
            .dispatch('reportOrderDetailTime', { operationId, type })
            .then(({ report }) => {
              this.operation.report[type] = report[type]
              console.log('Reported Time ' + type)
              this.logdata += 'successful<br><br>'
              resolve(this.getData())
            })
        })
      } else {
        if (type === 'travelStart') {
          const customDate = new Date(
            Math.floor(Date.now() / ms) * ms
          ).getTime()
          if (customDate === this.operation.report.onSite) {
            console.log(
              'Reisebeginn darf nicht größer oder gleich als onSite sein'
            )
          } else {
            return new Promise(resolve => {
              const operationId = this.operation.id
              this.$store
                .dispatch('reportCustomOrderDetailTime', {
                  operationId,
                  type,
                  customDate,
                })
                .then(({ report }) => {
                  this.operation.report[type] = report[type]
                  console.log(
                    'ZEIT: ' +
                      type +
                      ' ' +
                      format(customDate, 'D. MMM YYYY HH:mm')
                  )
                  resolve(this.getData())
                })
            })
          }
        } else if (type === 'onSite') {
          const customDate = new Date(
            Math.floor(Date.now() / ms) * ms
          ).getTime()
          if (customDate === this.operation.report.travelStart) {
            this.reportCustomTime('travelStart', customDate - ms).then(() => {
              return new Promise(resolve => {
                const operationId = this.operation.id
                this.$store
                  .dispatch('reportCustomOrderDetailTime', {
                    operationId,
                    type,
                    customDate,
                  })
                  .then(({ report }) => {
                    this.operation.report[type] = report[type]
                    console.log(
                      'ZEIT: ' +
                        type +
                        ' ' +
                        format(customDate, 'D. MMM YYYY HH:mm')
                    )
                    resolve(this.getData())
                  })
              })
            })
          } else {
            return new Promise(resolve => {
              const operationId = this.operation.id
              this.$store
                .dispatch('reportCustomOrderDetailTime', {
                  operationId,
                  type,
                  customDate,
                })
                .then(({ report }) => {
                  this.operation.report[type] = report[type]
                  console.log(
                    'ZEIT: ' +
                      type +
                      ' ' +
                      format(customDate, 'D. MMM YYYY HH:mm')
                  )
                  resolve(this.getData())
                })
            })
          }
        } else if (type === 'repaired') {
          return this.reportCustomTime('repaired', Date.now())
        }
      }
    },
    reportCustomTime(type, customDate) {
      let ms = 1000 * 60 * 1 //let ms = 1000 * 60 * 15
      if (this.operation.ticket_order_nr.toLowerCase().indexOf('udt') !== -1) {
        const operationId = this.operation.id
        this.$store
          .dispatch('reportCustomOrderDetailTime', {
            operationId,
            type,
            customDate,
          })
          .then(({ report }) => {
            this.operation.report[type] = report[type]
            console.log(
              'ZEIT: ' + type + ' ' + format(customDate, 'D. MMM YYYY HH:mm')
            )
          })
          .catch(err => {
            this.error[type] = err
          })
      } else {
        if (type === 'travelStart') {
          customDate = new Date(Math.floor(customDate / ms) * ms).getTime()
          if (customDate === this.operation.report.onSite) {
            console.log(
              'Reisebeginn darf nicht größer oder gleich als onSite sein'
            )
          } else {
            return new Promise(resolve => {
              const operationId = this.operation.id
              this.$store
                .dispatch('reportCustomOrderDetailTime', {
                  operationId,
                  type,
                  customDate,
                })
                .then(({ report }) => {
                  this.operation.report[type] = report[type]
                  console.log(
                    'ZEIT: ' +
                      type +
                      ' ' +
                      format(customDate, 'D. MMM YYYY HH:mm')
                  )
                  resolve(this.getData())
                })
            })
          }
        } else if (type === 'onSite') {
          customDate = new Date(Math.floor(customDate / ms) * ms).getTime()
          if (customDate === this.operation.report.travelStart) {
            this.reportCustomTime('travelStart', customDate - ms).then(() => {
              return new Promise(resolve => {
                const operationId = this.operation.id
                this.$store
                  .dispatch('reportCustomOrderDetailTime', {
                    operationId,
                    type,
                    customDate,
                  })
                  .then(({ report }) => {
                    this.operation.report[type] = report[type]
                    console.log(
                      'ZEIT: ' +
                        type +
                        ' ' +
                        format(customDate, 'D. MMM YYYY HH:mm')
                    )
                    resolve(this.getData())
                  })
              })
            })
          } else {
            return new Promise(resolve => {
              const operationId = this.operation.id
              this.$store
                .dispatch('reportCustomOrderDetailTime', {
                  operationId,
                  type,
                  customDate,
                })
                .then(({ report }) => {
                  this.operation.report[type] = report[type]
                  console.log(
                    'ZEIT: ' +
                      type +
                      ' ' +
                      format(customDate, 'D. MMM YYYY HH:mm')
                  )
                  resolve(this.getData())
                })
            })
          }
        } else if (type === 'repaired') {
          customDate = new Date(Math.ceil(customDate / ms) * ms).getTime()
          return new Promise(resolve => {
            const operationId = this.operation.id
            this.$store
              .dispatch('reportCustomOrderDetailTime', {
                operationId,
                type,
                customDate,
              })
              .then(({ report }) => {
                this.operation.report[type] = report[type]
                console.log(
                  'ZEIT: ' +
                    type +
                    ' ' +
                    format(customDate, 'D. MMM YYYY HH:mm')
                )
                resolve(this.getData())
              })
          })

          // } else if (type === 'repaired' || type === 'finished'){
          // customDate = new Date(Math.ceil(customDate / ms) * ms).getTime()
          // if (type === 'finished') {
          // this.reportCustomTime('repaired', customDate)
          // .then(() => {
          // customDate += 60000
          // return new Promise((resolve, reject) => {
          // const operationId = this.operation.id
          // this.$store
          // .dispatch('reportCustomOrderDetailTime', {
          // operationId,
          // type,
          // customDate,
          // })
          // .then(({ report }) => {
          // this.operation.report[type] = report[type]
          // console.log('ZEIT: ' + type + ' ' + format(customDate, 'D. MMM YYYY HH:mm'))
          // resolve(this.getData())
          // })
          // });
          // })
          // } else {
          // return new Promise((resolve, reject) => {
          // const operationId = this.operation.id
          // this.$store
          // .dispatch('reportCustomOrderDetailTime', {
          // operationId,
          // type,
          // customDate,
          // })
          // .then(({ report }) => {
          // this.operation.report[type] = report[type]
          // console.log('ZEIT: ' + type + ' ' + format(customDate, 'D. MMM YYYY HH:mm'))
          // resolve(this.getData())
          // })
          // });
          // }
        }
      }
    },
    reportDetail(type) {
      const operationId = this.operation.id
      let value = this[type]
      this.logdata += 'reportDetail(' + type + ')<br>'
      console.log('Type: ' + type)
      this.logdata += 'value: ' + value + '<br>'
      if (type === 'status') {
        this.logdata =
          'reportDetail(' + type + ')<br>' + 'value: ' + value + '<br>'
        if (value === 1) {
          this.logdata += 'if (' + value + ' === 1)<br>'
          value = 'done'
        } else if (value === 0) {
          this.logdata += 'if (' + value + ' === 0)<br>'
          value = 'rework'
        } else {
          this.logdata += 'if (' + value + ' !== 1 && ' + value + ' !== 0)<br>'
          value = 'planned'
        }
      } else if (type === 'cqc') {
        value = value.join()
      }
      console.log('Value: ' + value)
      this.logdata += 'value: ' + value + '<br>'
      console.log('Reported Detail ' + type)
      this.logdata += `tried reportDetail(${type})<br><br>`
      if (value) {
        return this.$store
          .dispatch('reportOrderDetail', { operationId, type, value })
          .then(({ report }) => {
            this.operation.report[type] = report[type]
            this.logdata += 'successful<br>'
          })
          .catch(err => {
            this.error[type] = err
            this.logdata += 'failed<br>'
          })
      }
    },
    reportReworkDetail(type) {
      const operationId = this.operation.id
      const blame = this.blame
      let item = this.blameDetailReason
      let value = this[type]
      this.logdata = ''
      this.logdata += 'reportReworkDetail(' + type + ')<br>'
      this.logdata += 'value: ' + value + '<br>'
      console.log('Type: ' + type)
      console.log('rework version')
      console.log('Value: ' + value)
      console.log('blame: ' + blame)
      const reworkId = item.reworkId
      console.log('Rework Descr: ' + item.description)
      console.log('Rework Id: ' + reworkId)
      this.logdata += 'Rework Descr: ' + item.description + '<br>'
      this.logdata += 'Rework ID: ' + reworkId + '<br>'
      if (value === 1) {
        this.logdata += 'if (' + value + ' === 1)<br>'
        value = 'done'
      } else if (value === 0) {
        this.logdata += 'if (' + value + ' === 0)<br>'
        value = 'rework'
      } else {
        this.logdata += 'if (' + value + ' !== 1 && ' + value + ' !== 0)<br>'
        value = 'planned'
      }
      console.log('Value: ' + value)
      this.logdata += 'value: ' + value + '<br>'

      if (value) {
        this.$store
          .dispatch('reportRework', { operationId, type, value, reworkId })
          .then(({ report }) => {
            this.operation.report[type] = report[type]
            this.logdata += 'successful<br>'
          })
          .catch(err => {
            this.error[type] = err
            this.logdata += 'failed<br>'
          })
      }
      console.log('Reported Detail ' + type)
      this.logdata += 'tried reportReworkDetail(' + type + ')<br>'
    },
    setTitle(reportType) {
      switch (reportType) {
        case 'travelStart':
          return this.$t('views.order.order_detail.travel_start')
        case 'onSite':
          return this.$t('views.order.order_detail.arrival')
        case 'repaired':
          return this.$t('views.order.order_detail.end_of_work')
        case 'finished':
          return this.$t('views.order.order_detail.finished_operation')
        default:
          return undefined
      }
    },
    setDistance() {
      if (this.operation.address.company === 'Toshiba') {
        //  'luftlinie'
      } else {
        // this.distance = 143
      }
    },
    sendMail() {
      const link =
        'mailto:v.boss@bentomax.de' +
        '?cc=v.boss@bentomax.de' +
        '&subject=' +
        escape('This is my subject') +
        '&body=' +
        escape(document.getElementById('testText').value)
      window.location.href = link
    },

    send_email(reason) {
      switch (reason) {
        case 'departure':
          Email.send({
            SecureToken: 'da376082-b227-42f9-a4d7-7722ac4f2a66',
            To: 'helpdesk@bentomax.de',
            From: 'toni_db@bentomax.de',
            Subject: 'DM_Abfahrt: ' + this.operation.id,
            Body:
              'Auftrags-ID: ' +
              this.operation.id +
              '<br>' +
              'Filiale: ' +
              this.operation.address.company +
              '<br>' +
              'Adresse: ' +
              this.operation.address.street +
              ' ' +
              this.operation.address.streetNo +
              '<br>' +
              this.operation.address.zip +
              ' ' +
              this.operation.address.city +
              '<br>' +
              'Techniker: ' +
              (this.$store.state.user.name
                ? this.$store.state.user.name
                : 'unbekannt') +
              '<br>' +
              'Nutzer-Email: ' +
              (this.$store.state.user.email
                ? this.$store.state.user.email
                : 'unbekannt') +
              '<br>' +
              'Zeit: ' +
              format(Date.now(), 'D. MMM YYYY HH:mm', {
                locale: deLocale,
              }) +
              '<br>' +
              "<a href='https://hawk.bentomax.de/operation/" +
              this.operation.id +
              "/show'>Zu Hawk</a><br><br>" +
              'Der Techniker ist nun unterwegs in die Filiale.',
          })
          return
        case 'arrival':
          Email.send({
            SecureToken: 'da376082-b227-42f9-a4d7-7722ac4f2a66',
            To: 'helpdesk@bentomax.de',
            From: 'toni_db@bentomax.de',
            Subject: 'DM_Ankunft: ' + this.operation.id,
            Body:
              'Auftrags-ID: ' +
              this.operation.id +
              '<br>' +
              'Filiale: ' +
              this.operation.address.company +
              '<br>' +
              'Adresse: ' +
              this.operation.address.street +
              ' ' +
              this.operation.address.streetNo +
              '<br>' +
              this.operation.address.zip +
              ' ' +
              this.operation.address.city +
              '<br>' +
              'Techniker: ' +
              (this.$store.state.user.name
                ? this.$store.state.user.name
                : 'unbekannt') +
              '<br>' +
              'Nutzer-Email: ' +
              (this.$store.state.user.email
                ? this.$store.state.user.email
                : 'unbekannt') +
              '<br>' +
              'Zeit: ' +
              format(Date.now(), 'D. MMM YYYY HH:mm', {
                locale: deLocale,
              }) +
              '<br>' +
              "<a href='https://hawk.bentomax.de/operation/" +
              this.operation.id +
              "/show'>Zu Hawk</a><br><br>" +
              'Der Techniker ist nun vor Ort.',
          })
          return
        case 'closeOrder':
          Email.send({
            SecureToken: 'da376082-b227-42f9-a4d7-7722ac4f2a66',
            To: 'helpdesk@bentomax.de',
            From: 'toni_db@bentomax.de',
            Subject: 'ClosedOrder: ' + this.operation.id,
            Body:
              'Auftrags-ID: ' +
              this.operation.id +
              '<br>' +
              'Techniker: ' +
              (this.$store.state.user.name
                ? this.$store.state.user.name
                : 'unbekannt') +
              '<br>' +
              'Nutzer-Email: ' +
              (this.$store.state.user.email
                ? this.$store.state.user.email
                : 'unbekannt') +
              '<br>' +
              'Zeit: ' +
              format(Date.now(), 'D. MMM YYYY HH:mm', {
                locale: deLocale,
              }) +
              '<br>' +
              "<a href='https://hawk.bentomax.de/operation/" +
              this.operation.id +
              "/show'>Zu Hawk</a>" +
              '<br>Log-Data:' +
              '<br>' +
              this.logdata,
          })
          return
        case 'hdd':
          Email.send({
            SecureToken: 'da376082-b227-42f9-a4d7-7722ac4f2a66',
            To: 'helpdesk@bentomax.de',
            From: 'toni_db@bentomax.de',
            Subject: 'TEST: UniData HDD ausgebaut',
            Body:
              'Eventuell nicht authentisch: Auftrags-ID: ' +
              this.operation.id +
              '<br>' +
              'Filiale: ' +
              this.operation.address.company +
              '<br>' +
              'Adresse: ' +
              this.operation.address.street +
              ' ' +
              this.operation.address.streetNo +
              '<br>' +
              this.operation.address.zip +
              ' ' +
              this.operation.address.city +
              '<br>' +
              'Techniker: ' +
              (this.$store.state.user.name
                ? this.$store.state.user.name
                : 'unbekannt') +
              '<br>' +
              'Nutzer-Email: ' +
              (this.$store.state.user.email
                ? this.$store.state.user.email
                : 'unbekannt') +
              '<br>' +
              'Zeit: ' +
              format(Date.now(), 'D. MMM YYYY HH:mm', {
                locale: deLocale,
              }) +
              '<br>' +
              "<a href='https://hawk.bentomax.de/operation/" +
              this.operation.id +
              "/show'>Zu Hawk</a><br><br>" +
              'Der Techniker hat eine HDD ausgebaut.<br>' +
              'try cqc: ' +
              this.cqc +
              '<br>' +
              this.bugText,
          })
          return
      }
    },
    checkForHDD() {
      var index = false
      var count = 0
      var bugText = ''
      if (this.cqcValues && this.cqc) {
        bugText += 'cqcValues:' + this.cqcValues + 'cqc:' + this.cqc
        for (let cqcValue of this.cqcValues) {
          if (cqcValue.description.toLowerCase().indexOf('hdd') !== -1) {
            bugText += 'cqcValue:' + cqcValue.description.toLowerCase()
            index = count
            bugText += 'indexcount:' + index
          }
          count++
          bugText += 'count:' + count
        }
      }
      if (index) {
        if (this.cqc.includes(index)) {
          bugText += 'this ' + this.cqc + ' includes(index):' + index
          this.bugText = bugText
          return true
        }
        this.bugText = bugText
        return false
      }
      this.bugText = bugText
      return index
    },

    // save() {
    // this.modal9 = false
    // const _this = this
    // const png = _this.$refs.signature.save()
    // const jpeg = _this.$refs.signature.save('image/jpeg')
    // const svg = _this.$refs.signature.save('image/svg+xml')
    // console.log(png)
    // console.log(jpeg)
    // console.log(svg)
    // this.signature = svg
    // return svg
    // },
    save() {
      const id = this.operation.id
      // this.modal9 = false
      const _this = this
      // const png = _this.$refs.signature.save()
      const jpeg = _this.$refs.signature.save('image/jpeg')
      // const svg = _this.$refs.signature.save('image/svg+xml')
      // console.log(png)
      console.log(jpeg)
      // console.log(svg)
      this.$store.dispatch('uploadSignature', { data: jpeg, id })
      return jpeg
    },
    clear() {
      // this.modal9 = false
      const _this = this
      _this.$refs.signature.clear()
    },
    makeKey(report, err) {
      return `${report}${err}`
    },
    addEventToCalendar(calendar) {
      const { address, operationDate, description } = this.operation
      const { company, street, streetNo, zip, city } = address
      const title = `${company}, ${street} ${streetNo}, ${zip} ${city}`.trim()

      // if apple make link for ical if not then google calendar
      if (calendar === 'apple') {
        return (
          config.API +
          '/util/vCalendar.html?start=' +
          format(operationDate, 'YYYY-MM-DD HH:mm') +
          '&end=' +
          format(operationDate + 60 * 60 * 1000, 'YYYY-MM-DD HH:mm') +
          '&summary=' +
          title +
          '&description=' +
          description +
          '&location=' +
          title +
          '&jwt_token=' +
          this.$store.state.token
        )
      } else
        window.open(
          encodeURI(
            [
              'https://www.google.com/calendar/render',
              '?action=TEMPLATE',
              '&text=' + (title || ''),
              '&dates=' + (this.formatTime(operationDate) || ''),
              '/' +
                (this.formatTime(
                  this.operation.operationDate + 30 * 60 * 1000
                ) || ''),
              '&details=' + (description || ''),
              '&location=' + (title || ''),
              '&sprop=&sprop=name:',
            ].join('')
          )
        )
    },
    formatTime(initialDate) {
      const date = new Date(initialDate)
      return date.toISOString().replace(/-|:|\.\d+/g, '')
    },
    userOS() {
      // const userAgent = window.navigator.userAgent
      const platform = window.navigator.platform
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
      // const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      const iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'apple'
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'apple'
      } else {
        os = 'not apple'
      }
      // else if (windowsPlatforms.indexOf(platform) !== -1) {
      //   os = 'Windows'
      // } else if (/Android/.test(userAgent)) {
      //   os = 'Android'
      // } else if (!os && /Linux/.test(platform)) {
      //   os = 'Linux'
      // }

      return os
    },
    openMapsDirection() {
      openDirection(this.operation.address)
    },
    openMapsSearch() {
      openSearch(this.operation.address)
    },
    openDocumentation() {
      if (this.hasDocumentationLink) {
        const ticketId = this.operation.ticket_order_nr.split('-').pop();
        const url = this.operation.additional_documentation[0].replace('##TICKETID##', ticketId);
        window.open(url)
      }
    },
    // getLocation() {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(this.showPosition)
    //   } else {
    //     console.log('Geo Location not supported by browser')
    //   }
    // },
    // // function that retrieves the position
    // showPosition(position) {
    //   var location = {
    //     longitude: position.coords.longitude,
    //     latitude: position.coords.latitude,
    //   }
    //   console.log(location)
    // },
    setDetailedReport() {
      console.log('blameText: ' + this.blameText)

      //
      // Removed the extra info for status===0
      // as requested via email on 28./29.09.2020
      //
      if (this.status === 1) {
        var input = ''
        for (let article of this.deliveryArticles) {
          if (article.reportStatus === 'Used') {
            input =
              input +
              '- ' +
              article.template.description +
              '\r\n' +
              '      SN: ' +
              article.sn +
              '\r\n'
          }
        }
        // var output = '';
        // for (let article of this.badParts) {
        //   output += '- '
        //     + article.description
        //     + '\r\n'
        //     + '      SN: '
        //     + article.sn
        //     + '\r\n'
        // }
        /* this.report = this.$t('views.order.order_detail.assignment_done', {
          date: format(this.operation.report['onSite'], 'D.MMM', {
            locale: deLocale,
          }),
          used_material: (input === '' ? this.$t('views.order.order_detail.none_2') : input),
          report: this.report
        }) */
      }
      console.log('setDetailedReport completed')
    },
    closeReport() {
      this.loading = true
      this.finished = true

      if (
        app &&
        app.ports &&
        app.ports.in_ &&
        this.operation &&
        this.operation.id &&
        this.isNewMaterialAllowed
      ) {
        app.ports.in_.send({
          msg: 'JsFinishOperation',
          data: this.operation.id,
        })
      }

      console.log('before reportTime')
      this.reportTime('repaired')
        .catch(() => {
          console.log('err in time')
          this.logdata += 'failed<br><br>'
          this.finished = false
        })
        .then(() => {
          console.log('then reportTime')
          return new Promise(resolve => {
            resolve(this.setDetailedReport())
          })
        })
        .then(() => {
          console.log('then2 reportTime')
          return this.reportDetail('report')
        })
        .catch(() => {
          this.finished = false
          console.log('reportDetail(report) failed')
          this.logdata += 'tried reportDetail()(failed)<br><br>'
        })
        .then(() => {
          console.log('then3 reportTime')
          this.logdata += 'start big closeOrder<br>'
          return new Promise(resolve => {
            const {
              cqc,
              distance,
              status,
              statusArray,
              finished,
              onSite,
              repaired,
              travelStart,
            } = this
            const id = this.operation.id
            console.log('out')
            this.$store
              .dispatch('closeOrder', {
                id,
                cqc,
                distance,
                status,
                statusArray,
                finished,
                onSite,
                repaired,
                travelStart,
              })
              .then(() => {
                this.logdata += 'closeOrder successful<br><br>'
                resolve('closeOrder done')
              })
              .catch(err => {
                this.logdata += 'closeOrder failed<br><br>'
                console.error('error in closeOrder-store', err)
              })
          })
        })
        .catch(err => {
          console.error('closeerror', err)
          this.logdata += 'closeerror<br><br>'
          this.finished = false
        })
        .then(result => {
          console.log(result)
          this.logdata += 'everything perfect'
          console.log(this.logdata)
          this.send_email('closeOrder')
          this.backToOrders()
        })
      this.saveOperationsPosition()
    },
    async closeReport2() {
      try {
        this.loading = true
        let promise = new Promise(resolve => {
          this.setDetailedReport()
          resolve('reportupdate-done')
        })
        let reportupdate = await promise
        console.log(reportupdate)

        let promise2 = new Promise(resolve => {
          this.reportDetail('report')
          resolve('report-done')
        })
        let report = await promise2
        console.log(report)

        let promise3 = await this.reportTime('repaired')
        await promise3

        let promise4 = new Promise(resolve => {
          const {
            cqc,
            distance,
            status,
            statusArray,
            finished,
            onSite,
            repaired,
            travelStart,
          } = this
          const id = this.operation.id
          console.log('out')
          this.$store
            .dispatch('closeOrder', {
              id,
              cqc,
              distance,
              status,
              statusArray,
              finished,
              onSite,
              repaired,
              travelStart,
            })
            .then(() => {
              console.log('closeOrder successful')
              resolve('closeOrder done')
            })
            .catch(err => {
              console.error('error in closeOrder-store', err)
              this.finished = false
            })
        })
        let closedone = await promise4
        console.log(closedone)
        this.backToOrders()
      } catch (err) {
        console.log(err)
      }
    },
    materialSearch(value, search) {
      const needle = search ? search.toLowerCase() : ''

      return value.filter(article => {
        const description = article.desciption
          ? article.desciption.toLowerCase()
          : ''
        const vendor = article.vendor ? article.vendor.toLowerCase() : ''
        const sn = article.sn ? article.sn.toLowerCase() : ''
        let embeddedSnIsMatch = false

        for (let a of this.storage) {
          if (a.description === article.description && a.sn) {
            embeddedSnIsMatch =
              embeddedSnIsMatch || a.sn.toLowerCase().includes(needle)
          }
        }

        return (
          description.includes(needle) ||
          vendor.includes(needle) ||
          sn.includes(needle) ||
          embeddedSnIsMatch
        )
      })
    },
    showMaterialModal(material) {
      this.materialReportStatus = material.reportStatus
      this.materialId = material.id
      this.modal7 = true
    },
    updateMaterialStatus(article, status, materialError) {
      this.$store
        .dispatch('reportMaterial', {
          operationId: this.operation.id,
          materialId: article.id,
          reportStatus: status,
          materialError: materialError,
        })
        .then(() => {
          this.getData()
        })
    },
    setMaterialUsed(article, sn) {
      if (article.status !== 'booked') {
        this.$store
          .dispatch('updateArticleStatus', {
            orderId: this.operation.id,
            deliveryNr: article.deliveryNr,
            zid: article.zid,
            sn: sn,
            status: 'booked',
          })
          .then(() => {
            this.updateMaterialStatus(article, 'Used')
          })
      } else {
        this.updateMaterialStatus(article, 'Used')
      }
    },
    setMaterialDOA(article, sn) {
      if (article.status !== 'booked') {
        this.$store
          .dispatch('updateArticleStatus', {
            orderId: this.operation.id,
            deliveryNr: article.deliveryNr,
            zid: article.zid,
            sn: sn,
            status: 'booked',
          })
          .then(() => {
            this.updateMaterialStatus(article, 'DOA', this.articleError)
          })
      } else {
        this.updateMaterialStatus(article, 'DOA', this.articleError)
      }
    },
    setMaterialNotInPackage(article) {
      this.$store
        .dispatch('updateArticleStatus', {
          orderId: this.operation.id,
          deliveryNr: article.deliveryNr,
          zid: article.zid,
          status: 'notInPackage',
        })
        .then(() => {
          this.getData()
        })
    },
    setMaterialNotUsed(article, sn) {
      if (article.reportStatus !== 'booked') {
        this.$store
          .dispatch('updateArticleStatus', {
            orderId: this.operation.id,
            deliveryNr: article.deliveryNr,
            zid: article.zid,
            sn: sn,
            status: 'booked',
          })
          .then(() => {
            this.updateMaterialStatus(article, 'NotUsed', '')
          })
      } else {
        this.updateMaterialStatus(article, 'NotUsed', '')
      }
    },
    async addMaterialFromWarehouse() {
      for (let article of this.storage) {
        if (article.selected === true) {
          if (!(article.cardstatus < 5)) {
            console.log('Add article from warehouse ' + article.description)
            await this.$store
              .dispatch('addMaterialFromWarehouse', {
                operationId: this.operation.id,
                articleId: article.id,
              })
              .then(() => {
                this.getData()
              })
          }
        }
      }
    },
    removeMaterialFromWarehouse(material) {
      this.$store
        .dispatch('removeMaterialFromWarehouse', {
          operationId: this.operation.id,
          materialId: material.id,
        })
        .then(() => {
          this.getData()
        })
    },
    replaceMaterial(article, sn) {
      if (article.fromBin) {
        this.$store
          .dispatch('replaceMaterial', {
            operationId: this.operation.id,
            materialId: article.id,
            templateId: this.changeTemplateId,
            sn: this.changePartSn,
            description: this.changePartDescription,
          })
          .then(() => {
            this.getData()
          })
      } else {
        if (article.status !== 'booked') {
          this.$store
            .dispatch('updateArticleStatus', {
              orderId: this.operation.id,
              deliveryNr: article.deliveryNr,
              zid: article.zid,
              sn: sn,
              status: 'booked',
            })
            .then(() => {
              this.$store
                .dispatch('replaceMaterial', {
                  operationId: this.operation.id,
                  materialId: article.id,
                  templateId: this.changeTemplateId,
                  sn: this.changePartSn,
                  description: this.changePartDescription,
                })
                .then(() => {
                  this.getData()
                })
            })
        } else {
          this.$store
            .dispatch('replaceMaterial', {
              operationId: this.operation.id,
              materialId: article.id,
              templateId: this.changeTemplateId,
              sn: this.changePartSn,
              description: this.changePartDescription,
            })
            .then(() => {
              this.getData()
            })
        }
      }
    },

    showFieldModal(field) {
      this.fieldId = field.id
      this.fieldValue = field.value
      this.fieldDescription = field.description
      this.modalField = true
    },
    updateField() {
      this.$store
        .dispatch('reportField', {
          operationId: this.operation.id,
          fieldId: this.fieldId,
          fieldValue: this.fieldValue,
        })
        .then(() => {
          this.getData()
        })
      this.modalField = false
    },
    showPartModal(part) {
      this.partRMAOld = part.rma
      this.partRMA = part.rma
      this.partOldSn = part.oldSN
      this.partNewSn = part.newSN
      this.partDescription = part.description
      this.modalPart = true
    },
    updateParts() {
      // Remove changed part
      this.sortedParts = this.sortedParts.filter(
        part => part.rma !== this.partRMAOld
      )
      // Add the changed part
      this.sortedParts.push({
        rma: this.partRMA,
        oldSN: this.partOldSn,
        newSN: this.partNewSn,
        description: this.partDescription,
      })
      this.$store
        .dispatch('reportParts', {
          operationId: this.operation.id,
          parts: this.sortedParts,
        })
        .then(() => {
          this.getData()
        })
      this.modalPart = false
    },
    removePart() {
      this.sortedParts = this.sortedParts.filter(
        part => part.rma !== this.partRMAOld
      )
      this.$store
        .dispatch('reportParts', {
          operationId: this.operation.id,
          parts: this.sortedParts,
        })
        .then(() => {
          this.getData()
        })
      this.modalPart = false
    },
    addPart() {
      this.sortedParts.push({
        rma: this.newPartRMA,
        oldSN: this.newPartOldSn,
        newSN: this.newPartNewSn,
        description: this.newPartDescription,
      })
      //  temp until clean solution found; todo
      this.newPartRMA = undefined
      this.newPartOldSn = undefined
      this.newPartNewSn = undefined
      this.newPartDescription = undefined
      //
      this.updateParts()
      this.modalNewPart = false
    },
    completeFileUpload() {
      console.log('Complete upload')
      this.getData()
    },
    deleteFile(filename) {
      this.$store
        .dispatch('deleteFile', {
          operationId: this.operation.id,
          filename: filename,
        })
        .then(() => {
          this.getData()
        })
    },
    openDeviceList() {
      this.$router.push(`/orders/${this.$route.params.orderId}/devices`)
    },
    saveOperationsPosition() {
      let position = this.$refs.geolocationChecker.position
      if (position) {
        console.log(`Saving position ${position.coords.latitude}, ${position.coords.longitude} to operation ${this.operation.id}`)
        this.$store
              .dispatch('saveGeolocation', {
                id: this.operation.id,
                pos_latitude: position.coords.latitude,
                pos_longitude: position.coords.longitude,
                pos_date: new Date()
              })
              .then(() => {
                console.log('saved geolocation successfully')
              })
              .catch(err => {
                console.error('error while saving geolocation', err)
              })
      } else {
        console.log('no geolocation to save')
      }
    }
  },
}
</script>

<style scoped lang="scss">
.size-full {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    max-height: 30vh;
    margin: 1rem 0;
  }
}

.uppy-Dashboard {
  height: 300px !important;
}

.link {
  color: initial;
  text-decoration: none;
}
#apper {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.nopadding {
  padding: 0;
}

.nomargin {
  margin: 0;
}

.ta-center {
  text-align: center;
}
.ta-right {
  text-align: right;
}
.ta-left {
  text-align: left;
}

.status-button {
  margin: 0;
  padding: 0;
  min-width: 0;
  width: 5rem;
  height: 4rem;
}
.flex-column {
  flex-direction: column;
}
.h-1 {
  height: 1rem;
}
.h-2 {
  height: 2rem;
}
.h-3 {
  height: 3rem;
}
.h-4 {
  height: 4rem;
}
.h-5 {
  height: 5rem;
}
.w-1 {
  width: 1rem;
}
.w-2 {
  width: 2rem;
}
.w-3 {
  width: 3rem;
}
.w-4 {
  width: 4rem;
}
.w-5 {
  width: 5rem;
}
.min-w-0 {
  min-width: 0;
}
.m-05 {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}

.fade {
  position: relative;
  //  3 for two lines, 4.5 for three lines
  height: 3rem;
  overflow: hidden;
}
.fade:after {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 1.2em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
}

.spinner-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
